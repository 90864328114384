import React from "react";
import { DateTime } from "luxon";

import { useDriverMeQuery } from "queries/drivers";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

import {
    useRegisterRaceMutation,
    useUnregisterRaceMutation,
} from "queries/races";

import { CogIcon, LoginIcon, SaveIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";

function RaceRegister({ _id, name, date, drivers: { active, waiting } }) {
    const navigate = useNavigate();

    const { keycloak, initialized } = useKeycloak();
    const driverMe = useDriverMeQuery({
        enabled: initialized,
        retry: false,
    });

    const alreadyRegistered =
        active.findIndex((d) => d.driver._id === driverMe?.data?._id) !== -1 ||
        waiting.findIndex((d) => d.driver._id === driverMe?.data?._id) !== -1;

    const { mutate: register, isLoading: registerIsLoading } =
        useRegisterRaceMutation();
    const { mutate: unregister, isLoading: unregisterIsLoading } =
        useUnregisterRaceMutation();

    if (initialized && keycloak.authenticated) {
        return (
            <div className="mt-8">
                <h2 className="font-F1Bold text-md lg:text-xl text-slate-300">
                    Inscription
                </h2>
                {driverMe?.isSuccess && driverMe?.data === "" && (
                    <>
                        <div className="text-white my-4">
                            Pour t'inscrire à cette course tu dois d'abord
                            t'inscrire à la ligue
                        </div>
                        <button
                            type="button"
                            className="self-center flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500"
                            onClick={() => {
                                navigate("/register");
                            }}
                        >
                            M'inscrire à la ligue
                            <SaveIcon className="ml-2 -mr-1 h-5 w-5" />
                        </button>
                    </>
                )}
                {driverMe?.isSuccess &&
                    driverMe?.data !== "" &&
                    driverMe?.data.registration === "accepted" &&
                    !alreadyRegistered && (
                        <>
                            <div className="text-white my-4">
                                Pour t'inscrire au {name} qui aura lieu le{" "}
                                <span className="text-slate-300 font-bold">
                                    {DateTime.fromISO(date)
                                        .setLocale("fr-FR")
                                        .toFormat("dd MMM yyyy")}
                                </span>{" "}
                                à partir de{" "}
                                <span className="text-slate-300 font-bold">
                                    {DateTime.fromISO(date)
                                        .setLocale("fr-FR")
                                        .toFormat("HH:mm")}
                                </span>
                                , clique sur le bouton ci-dessous.
                            </div>
                            <button
                                type="button"
                                className="self-center flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70"
                                onClick={() => {
                                    register({ _id });
                                }}
                                disabled={registerIsLoading}
                            >
                                M'inscrire à cette course
                                {!registerIsLoading && (
                                    <SaveIcon className="ml-2 -mr-1 h-5 w-5" />
                                )}
                                {registerIsLoading && (
                                    <CogIcon className="ml-2 -mr-1 h-5 w-5 animate-spin" />
                                )}
                            </button>
                        </>
                    )}
                {driverMe?.isSuccess &&
                    driverMe?.data !== "" &&
                    driverMe?.data.registration === "accepted" &&
                    alreadyRegistered && (
                        <>
                            <div className="text-white my-4">
                                Félicitations ! tu es inscrit au {name} qui aura
                                lieu le{" "}
                                <span className="text-slate-300 font-bold">
                                    {DateTime.fromISO(date)
                                        .setLocale("fr-FR")
                                        .toFormat("dd MMM yyyy")}
                                </span>{" "}
                                à partir de{" "}
                                <span className="text-slate-300 font-bold">
                                    {DateTime.fromISO(date)
                                        .setLocale("fr-FR")
                                        .toFormat("HH:mm")}
                                </span>
                                .
                            </div>
                            <button
                                type="button"
                                className="self-center flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70"
                                onClick={() => {
                                    unregister({ _id });
                                }}
                                disabled={unregisterIsLoading}
                            >
                                Me désinscrire
                                {!unregisterIsLoading && (
                                    <XCircleIcon className="ml-2 -mr-1 h-5 w-5" />
                                )}
                                {unregisterIsLoading && (
                                    <CogIcon className="ml-2 -mr-1 h-5 w-5 animate-spin" />
                                )}
                            </button>
                        </>
                    )}
                {driverMe?.isSuccess &&
                    driverMe?.data !== "" &&
                    driverMe?.data.registration !== "accepted" && (
                        <>
                            <div className="text-white my-4">
                                Ton inscription à la ligue est en cours
                                d'examen. Reviens plus tard pour t'inscrire à
                                cette course.
                            </div>
                        </>
                    )}
            </div>
        );
    } else
        return (
            <div className="mt-8">
                <h2 className="font-F1Bold text-xl text-slate-300">
                    Inscription
                </h2>
                <div className="text-white my-4">
                    Pour t'inscrire à cette course tu dois d'abord te connecter
                    !
                </div>
                <button
                    type="button"
                    className="self-center flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500"
                    onClick={() => {
                        keycloak.login();
                    }}
                >
                    Connexion
                    <LoginIcon className="ml-2 -mr-1 h-5 w-5" />
                </button>
            </div>
        );
}

export default RaceRegister;
