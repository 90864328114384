import { createSlice } from "@reduxjs/toolkit";
import asideReducers from "store/reducers/aside";

export const asideSlice = createSlice({
    name: "aside",
    initialState: {
        opened: false,
        props: {},
    },
    reducers: asideReducers,
});

// Action creators are generated for each case reducer function
export const { open, close, clear } = asideSlice.actions;

const asideReducer = asideSlice.reducer;

export default asideReducer;
