import React from "react";
import { DateTime } from "luxon";
import ReactCountryFlag from "react-country-flag";
import classNames from "classnames";

function RaceCard({ _id, name, shortName, circuit, date, results }) {
    return (
        <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded p-4">
            <div className="flex items-center justify-between">
                <div>
                    {date ? (
                        <>
                            <span className="font-F1Wide text-white mr-2 py-1">
                                {DateTime.fromISO(date).toFormat("dd")}
                            </span>
                            <span className="font-F1Wide uppercase bg-slate-800 text-white px-1 py-1 rounded mr-2">
                                {DateTime.fromISO(date)
                                    .setLocale("fr-FR")
                                    .toFormat("MMM")}
                            </span>
                        </>
                    ) : (
                        <span className="font-F1Wide uppercase bg-slate-800 text-white px-1 py-1 rounded mr-2">
                            TBD
                        </span>
                    )}
                </div>
                <ReactCountryFlag
                    countryCode={circuit.country}
                    svg
                    style={{
                        width: "2em",
                        height: "2em",
                    }}
                />
            </div>
            <div className="font-F1Bold text-white text-lg">{shortName}</div>
            <div className="text-white text-slate-300">{name}</div>
            {results.race.length > 0 ? (
                <div className="text-white text-slate-300 border-t border-t-slate-500 mt-4 pt-4 pl-6 flex flex-col gap-y-2 ">
                    {results.race.slice(0, 3).map(({ driver, position }) => (
                        <div
                            key={`${_id}-${position}`}
                            className={classNames("flex flex-row items-center")}
                        >
                            <span className="mr-2 text-xs font-F1Bold">
                                {position}
                            </span>
                            <img
                                className="rounded-full w-6 border-2 border-slate-500"
                                src={
                                    driver.user?.discord?.avatar
                                        ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                        : "/avatar.png"
                                }
                                alt=""
                            />
                            <span className="text-white text-xs ml-2 font-F1">
                                {driver.name}
                            </span>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="border-t border-t-slate-500 mt-4 pt-4">
                    <img
                        className="h-36 m-auto"
                        src={`/circuits/icons/${circuit.picture}.png`}
                        alt={`${circuit.name}`}
                    />
                </div>
            )}
        </div>
    );
}

export default RaceCard;
