import React from "react";
import { Routes, Route } from "react-router-dom";

import Public from "pages/Public";
import Admin from "pages/Admin";

import { publicRoutes } from "routers/publicRoutes";
import { adminRoutes } from "routers/adminRoutes";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Public />}>
                {publicRoutes.map((route, key) => {
                    if (route.routes) {
                        return (
                            <Route
                                key={key}
                                path={route.path}
                                element={route.element}
                            >
                                {subRoutes(route.routes)}
                            </Route>
                        );
                    } else return <Route key={key} {...route} />;
                })}
            </Route>
            <Route path="/admin" element={<Admin />}>
                {adminRoutes.map((route, key) => {
                    if (route.routes) {
                        return (
                            <Route
                                key={key}
                                path={route.path}
                                element={route.element}
                            >
                                {subRoutes(route.routes)}
                            </Route>
                        );
                    } else return <Route key={key} {...route} />;
                })}
            </Route>
        </Routes>
    );
}

const subRoutes = (routes) => {
    return routes.map((route, key) => {
        if (route.routes)
            return (
                <Route key={key} path={route.path} element={route.element}>
                    {subRoutes(route.routes)}
                </Route>
            );
        else return <Route key={key} {...route} />;
    });
};

export default App;
