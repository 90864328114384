import React, { useState } from "react";
import { useDriversQuery } from "queries/drivers";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

function Drivers() {
    const currentSeasonId = useSelector((state) => state.admin.season);

    const [filters, setFilters] = useState([
        { name: "Validé", value: "accepted", current: true },
        { name: "En attente de validation", value: "pending", current: false },
        { name: "Refusé", value: "refused", current: false },
    ]);

    const drivers = useDriversQuery(
        currentSeasonId,
        { registration: filters.find((f) => f.current).value },
        { sort: "-team, name" }
    );

    const navigate = useNavigate();

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold">Pilotes</h1>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <div className="border-b border-slate-700 mb-4">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {filters.map((filter) => (
                            <span
                                key={filter.value}
                                className={classNames(
                                    filter.current
                                        ? "border-slate-300 text-slate-300"
                                        : "border-transparent text-slate-500 hover:text-slate-300 hover:border-slate-300",
                                    "whitespace-nowrap py-2 px-1 border-b-2 font-F1 font-medium text-sm cursor-pointer"
                                )}
                                onClick={() => {
                                    setFilters((currentState) => {
                                        return currentState.map((f) => ({
                                            ...f,
                                            current:
                                                f.value === filter.value
                                                    ? true
                                                    : false,
                                        }));
                                    });
                                }}
                            >
                                {filter.name}
                            </span>
                        ))}
                    </nav>
                </div>
                <table className="w-full text-white">
                    <thead className="bg-slate-700 font-F1 text-sm">
                        <tr>
                            <th className="text-left p-2">Pilote</th>
                            <th className="text-left p-2">Écurie</th>
                            <th className="text-left p-2">Numéro de voiture</th>
                        </tr>
                    </thead>
                    <tbody>
                        {drivers.isSuccess &&
                            drivers.data &&
                            drivers.data.rows.map((driver) => (
                                <tr
                                    key={driver._id}
                                    className="even:bg-slate-700 hover:bg-slate-600 cursor-pointer select-none"
                                    onClick={() => navigate(`${driver._id}`)}
                                >
                                    <td className="text-left p-2">
                                        <div className="flex items-center text-white">
                                            <img
                                                className="rounded-full h-7 w-7 border-2 border-slate-500 mr-2"
                                                src={
                                                    driver.user?.discord?.avatar
                                                        ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                                        : "/avatar.png"
                                                }
                                                alt=""
                                            />
                                            {driver.name}
                                        </div>
                                    </td>
                                    <td className="text-left p-2">
                                        <div className="flex items-center">
                                            <span
                                                className="border-l-4 pl-2 text-slate-300"
                                                style={{
                                                    borderLeftColor:
                                                        driver.team?.color,
                                                }}
                                            >
                                                {driver.team?.name}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-left p-2">
                                        {driver.raceNumber}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Drivers;
