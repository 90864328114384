import ReactCountryFlag from "react-country-flag";
import React, { Fragment } from "react";
import { useLastRacesResultsQuery } from "queries/races";
import DriverPosition from "./DriverPosition";
import { Duration } from "luxon";
import { groupBy } from "lodash";
import { useDialog } from "components/Dialog/dialog.hook";
import RaceStandingAside from "pages/Public/Home/components/RaceStandingAside";

import { InformationCircleIcon } from "@heroicons/react/solid";
import {useSelector} from "react-redux";

function LastRacesResults() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const results = useLastRacesResultsQuery(currentSeasonId);

    const { openAside } = useDialog();

    return (
        <>
            {results.data?.rows.length > 0 &&
                results.data?.rows.map((race) => {
                    return (
                        <Fragment key={race._id}>
                            <div className="bg-slate-700 p-2 rounded flex justify-between border-l-2 border-slate-600">
                                <div className="flex items-center ">
                                    <ReactCountryFlag
                                        countryCode={race.circuit.country}
                                        svg
                                        className="mr-2"
                                        style={{
                                            width: "2em",
                                            height: "2em",
                                        }}
                                    />
                                    <div className="text-white font-F1Bold uppercase flex items-center">
                                        Résultats {race.name}
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded px-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500"
                                    onClick={() =>
                                        openAside(RaceStandingAside, {
                                            raceId: race._id,
                                        })
                                    }
                                >
                                    <InformationCircleIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mb-8 mt-8">
                                <div
                                    style={{
                                        backgroundImage: `url(/background/${
                                            findDriver(race.results.race, 1)
                                                .team.car
                                        })`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                >
                                    <div className="grid grid-cols-3 gap-2 rounded px-2 py-12 bg-slate-800 bg-opacity-70">
                                        <DriverPosition
                                            position={2}
                                            driver={
                                                findDriver(race.results.race, 2)
                                                    .driver
                                            }
                                            team={
                                                findDriver(race.results.race, 2)
                                                    .team
                                            }
                                        />
                                        <DriverPosition
                                            position={1}
                                            driver={
                                                findDriver(race.results.race, 1)
                                                    .driver
                                            }
                                            team={
                                                findDriver(race.results.race, 1)
                                                    .team
                                            }
                                        />
                                        <DriverPosition
                                            position={3}
                                            driver={
                                                findDriver(race.results.race, 3)
                                                    .driver
                                            }
                                            team={
                                                findDriver(race.results.race, 3)
                                                    .team
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded justify-center items-center">
                                        <div className="font-F1Bold text-slate-400 mb-2">
                                            Participants
                                        </div>
                                        <div className="font-F1Bold text-white text-3xl">
                                            {race.results.race.length}
                                        </div>
                                    </div>
                                    <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded justify-center items-center">
                                        <div className="font-F1Bold text-slate-400 mb-2">
                                            Abandons
                                        </div>
                                        <div className="font-F1Bold text-white text-3xl">
                                            {getDNF(race.results.race)}
                                        </div>
                                    </div>
                                    <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded justify-center items-center">
                                        <div className="font-F1Bold text-slate-400 mb-2">
                                            Meilleur Tour
                                        </div>
                                        <div className="font-F1Bold text-white text-xl">
                                            {
                                                getBestLap(race.results.race)
                                                    .driver.name
                                            }
                                        </div>
                                        <div className="font-F1 text-slate-300 text-lg">
                                            {Duration.fromMillis(
                                                getBestLap(race.results.race)
                                                    .bestLapTime
                                            ).toFormat("m:ss.SSS")}
                                        </div>
                                    </div>
                                    <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded justify-center items-center">
                                        <div className="font-F1Bold text-slate-400 mb-2">
                                            Meilleure Écurie
                                        </div>
                                        <div className="font-F1Bold text-white text-xl">
                                            {
                                                getBestTeam(
                                                    race.results.race,
                                                    race.results.qualifying
                                                ).team.name
                                            }
                                        </div>
                                        <div className="font-F1 text-slate-300 text-lg">
                                            {
                                                getBestTeam(
                                                    race.results.race,
                                                    race.results.qualifying
                                                ).points
                                            }{" "}
                                            pts
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            {results.data?.rows.length === 0 && (
                <div className="text-center text-white font-F1 mt-8">
                    Aucun résultat pour le moment
                </div>
            )}
        </>
    );
}

const findDriver = (race, position) => {
    return race.find((r) => r.position === position);
};

const getDNF = (race) => {
    return race.filter(
        (r) =>
            r.resultStatus === 4 ||
            r.resultStatus === 5 ||
            r.resultStatus === 6 ||
            r.resultStatus === 7
    ).length;
};

const getBestLap = (race) => {
    return race
        .filter((d) => d.bestLapTime !== 0)
        .sort((a, b) => a.bestLapTime - b.bestLapTime)[0];
};

const getBestTeam = (race, qualifying) => {
    return Object.entries(groupBy(race, "team._id"))
        .map(([key, team]) => {
            return {
                points: team.reduce(
                    (res, driver) =>
                        (res +=
                            driver.points +
                            (qualifying.find(
                                (d) => d.driver._id === driver.driver._id
                            )?.points || 0)),
                    0
                ),
                team: team[0].team,
                drivers: team,
            };
        })
        .sort((a, b) => b.points - a.points)[0];
};

export default LastRacesResults;
