import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useNextRaceQuery } from "queries/races";
import { DateTime } from "luxon";
import { DATETIME_FULL } from "luxon/src/impl/formats";
import {useSelector} from "react-redux";

function NextRace() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const nextRace = useNextRaceQuery(currentSeasonId);

    return (
        <div className="bg-slate-700 p-2 rounded flex flex-col items-center border-l-2 border-slate-600">
            <div className="text-gray-400 text-sm mb-1">
                Prochain Grand Prix
            </div>
            <div className="text-white font-F1Bold uppercase flex items-center">
                <ReactCountryFlag
                    countryCode={nextRace.data?.circuit.country}
                    svg
                    className="mr-2"
                />
                {nextRace.data?.shortName}
            </div>
            {nextRace.data?.date && (
                <div className="text-gray-400 text-sm mt-1">
                    {DateTime.fromISO(nextRace.data?.date).toLocaleString(
                        DATETIME_FULL
                    )}
                </div>
            )}
        </div>
    );
}

export default NextRace;
