import React from "react";
import { useTeamsStandingByRacesQuery } from "queries/standings";
import "pages/Public/Standings/index.scss";
import {useSelector} from "react-redux";

function Standings() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const standing = useTeamsStandingByRacesQuery(currentSeasonId);

    return (
        <div className="w-full max-w-7xl mx-auto xl:px-8 py-8">
            <table className="standings w-full text-white">
                <thead className="bg-slate-700 font-bold text-sm">
                    <tr>
                        <th className="text-left p-2 pl-4 text-xs">Pos.</th>
                        <th className="text-left p-2 pl-4 text-xs">Pilote</th>
                        {standing.isSuccess && standing.data.length
                            ? standing.data[0]?.races
                                  .sort((a, b) => a.position - b.position)
                                  .map((race) => {
                                      return (
                                          <th
                                              key={race._id}
                                              className="text-center p-2 text-sm"
                                          >
                                              {race.code}
                                          </th>
                                      );
                                  })
                            : undefined}
                        <th className="text-center p-2 text-xs border-l-slate-600 border-l-4">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {standing.isSuccess && standing.data.length
                        ? standing.data?.map(
                              ({ driver, races, totalPoints }, key) => {
                                  return (
                                      <tr
                                          key={driver._id}
                                          className="odd:bg-slate-700"
                                      >
                                          <td className="text-center p-2 font-F1Bold text-xs">
                                              {key + 1}
                                          </td>
                                          <td className="text-left p-2 pl-4">
                                              <div className="flex gap-x-2 items-center">
                                                  <img
                                                      className="rounded-full h-7 w-7 border-2 border-slate-500"
                                                      src={
                                                          driver.user?.discord
                                                              ?.avatar
                                                              ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                                              : "/avatar.png"
                                                      }
                                                      alt=""
                                                  />
                                                  <div className="flex flex-col justify-center">
                                                      <span className="text-sm">
                                                          {driver.name}
                                                      </span>
                                                      {driver.team && (
                                                          <span
                                                              className="text-xs border-l-4 pl-2"
                                                              style={{
                                                                  borderLeftColor:
                                                                      driver
                                                                          .team
                                                                          ?.color,
                                                              }}
                                                          >
                                                              {
                                                                  driver.team
                                                                      ?.name
                                                              }
                                                          </span>
                                                      )}
                                                  </div>
                                              </div>
                                          </td>
                                          {races
                                              .sort(
                                                  (a, b) =>
                                                      a.position - b.position
                                              )
                                              .map((race) => {
                                                  return (
                                                      <td
                                                          key={race._id}
                                                          className="text-center p-2"
                                                      >
                                                          {race.points}
                                                      </td>
                                                  );
                                              })}
                                          <td className="text-center p-2 border-l-slate-600 border-l-4">
                                              {totalPoints}
                                          </td>
                                      </tr>
                                  );
                              }
                          )
                        : undefined}
                </tbody>
            </table>
        </div>
    );
}

export default Standings;
