import React from "react";
import classNames from "classnames";

function DriverPosition({ position, driver, team }) {
    return (
        <div
            className={classNames("flex flex-col items-center justify-end", {
                "mb-4": position === 1,
            })}
        >
            <img
                className="rounded-full w-20 border-2 border-slate-500"
                src={
                    driver.user?.discord?.avatar
                        ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                        : "/avatar.png"
                }
                alt=""
            />
            <div className="text-white text-sm font-F1Bold my-4">
                {driver.name}
            </div>
            <div
                className="bg-slate-700 rounded border-2 py-2 px-6 text-xl font-F1Black text-white"
                style={{
                    borderColor: team.color,
                }}
            >
                {position}
            </div>
        </div>
    );
}
export default DriverPosition;
