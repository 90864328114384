import React, { useEffect, useState } from "react";
import { CogIcon, LoginIcon } from "@heroicons/react/outline";
import { useKeycloak } from "@react-keycloak/web";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function RegisterLogin() {
    const navigate = useNavigate();
    const { keycloak, initialized } = useKeycloak();
    const user = keycloak.tokenParsed;
    const [nextStepActive, setNextStepActive] = useState(false);

    useEffect(() => {
        if (keycloak.authenticated) {
            axios.get("/me").then(({ data, status }) => {
                if (status === 204) {
                    axios.post("/users/register").then(() => {
                        setNextStepActive(true);
                    });
                } else {
                    setNextStepActive(true);
                }
            });
        }
    }, [keycloak, initialized]);

    if (keycloak.authenticated)
        return (
            <div className="flex flex-col items-center gap-y-4">
                <p className="text-white">
                    Bienvenue {user?.discord?.username}, clique sur suivant pour
                    continuer ton inscription !
                </p>
                <button
                    type="button"
                    className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70"
                    onClick={() => {
                        navigate("../informations", { replace: true });
                    }}
                    disabled={!nextStepActive}
                >
                    Suivant
                    {nextStepActive && (
                        <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" />
                    )}
                    {!nextStepActive && (
                        <CogIcon className="ml-2 -mr-1 h-5 w-5 animate-spin" />
                    )}
                </button>
            </div>
        );
    else
        return (
            <div className="flex flex-col items-center gap-y-4">
                <p className="text-white">
                    Pour continuer ton inscription à la saison 5 de la Ligue
                    Azgharienne, connecte-toi avec ton compte Discord !
                </p>
                <button
                    type="button"
                    className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500"
                    onClick={() => {
                        keycloak.login();
                    }}
                >
                    Connexion
                    <LoginIcon
                        className="ml-2 -mr-1 h-5 w-5"
                        aria-hidden="true"
                    />
                </button>
            </div>
        );
}

export default RegisterLogin;
