import { createSlice } from "@reduxjs/toolkit";
import modalReducers from "store/reducers/modal";

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        opened: false,
        props: {},
    },
    reducers: modalReducers,
});

// Action creators are generated for each case reducer function
export const { open, close, clear } = modalSlice.actions;

const modalReducer = modalSlice.reducer;

export default modalReducer;
