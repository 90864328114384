import Keycloak from "keycloak-js";

const keycloak = Keycloak({
    url:
        process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://auth.azgharie.net",
    realm: "azgharie",
    clientId: "f1-manager-app",
});

export default keycloak;
