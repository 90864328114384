import { createSlice } from "@reduxjs/toolkit";

export const publicSlice = createSlice({
    name: "public",
    initialState: {
        season: "654feee45aac5c05b0eac652",
    },
    reducers: {
        setSeason: (state, action) => {
            state.season = action.payload;
        },
    },
});

export const { setSeason } = publicSlice.actions;

const publicReducer = publicSlice.reducer;

export default publicReducer;
