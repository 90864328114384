import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "keycloak";

import { QueryClient, QueryClientProvider } from "react-query";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import messages_fr from "./translations/fr-FR.json";
import messages_en from "./translations/en-GB.json";

import store from "store";
import App from "./App";

import Modal from "components/Dialog/Modal";
import Aside from "components/Dialog/Aside";

const locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    "fr";

const messages = {
    "fr-FR": messages_fr,
    "en-GB": messages_en,
};

const queryClient = new QueryClient();

axios.defaults.baseURL =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3000/api"
        : "https://liguef1.azgharie.net/api";

const tokenLogger = (tokens) => {
    if (tokens.token) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${tokens.token}`;
    }
};

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <App />
                            <Modal />
                            <Aside />
                        </BrowserRouter>
                    </QueryClientProvider>
                </Provider>
            </IntlProvider>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your pages, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
