import React from "react";
import { useDriversStandingQuery } from "queries/standings";
import {useSelector} from "react-redux";

function DriversStanding() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const driversStanding = useDriversStandingQuery(currentSeasonId);

    return (
        <table className="w-full mt-6">
            <thead>
                <tr className="font-F1 text-xs text-gray-400 text-left">
                    <th>Pos</th>
                    <th />
                    <th>Pilote</th>
                    <th>Pts</th>
                </tr>
            </thead>
            <tbody className="text-white text-sm">
                {driversStanding.data?.map(({ driver, points }, key) => {
                    return (
                        <tr key={driver._id}>
                            <td className="py-1">{key + 1}</td>
                            <td className="py-1">
                                <div className="flex items-center justify-center">
                                    {driver.discord?.avatar && (
                                        <img
                                            className="rounded-full h-7 w-7 border-2 border-slate-500"
                                            src={driver.discord?.avatar}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </td>
                            <td className="py-1">{driver.name}</td>
                            <td className="py-1">{points}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default DriversStanding;
