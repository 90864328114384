import React from "react";

function RegisterConfirmation() {
    return (
        <div>
            <p className="text-white text-center text-lg">
                Félicitations ton inscription est enregistrée.
            </p>
        </div>
    );
}

export default RegisterConfirmation;
