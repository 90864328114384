import React, { useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { useRaceQuery, useUpdateRaceMutation } from "queries/races";
import { useParams } from "react-router-dom";
import CustomField from "components/CustomField";
import { useTeamsQuery } from "queries/teams";
import { useSelector } from "react-redux";
import { Duration } from "luxon";
import { ClockIcon, CogIcon, SaveIcon, XIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";

function RaceRegistrations() {
    const { raceId } = useParams();
    const currentSeasonId = useSelector((state) => state.admin.season);

    const [drivers, setDrivers] = useState([]);
    const [waitingDrivers, setWaitingDrivers] = useState([]);

    const teams = useTeamsQuery(currentSeasonId);
    const { isSuccess } = useRaceQuery(
        raceId,
        { populate: "driver, team" },
        {
            onSuccess: (data) => {
                setDrivers(data.drivers.active);
                setWaitingDrivers(data.drivers.waiting);
            },
            refetchOnWindowFocus: false,
        }
    );
    const { mutate: updateRace, isLoading } = useUpdateRaceMutation();

    return (
        <>
            <div className="grid grid-cols-12">
                <div className="col-span-3">
                    <h2 className="font-F1 text-sm text-white mb-2">
                        Liste d'attente
                    </h2>
                    <div className="space-y-2">
                        {waitingDrivers.map((driver) => {
                            return (
                                <div
                                    key={driver.driver._id}
                                    className="flex py-2 items-center"
                                >
                                    {drivers.length < 20 && (
                                        <div
                                            className="mr-2 cursor-pointer bg-slate-600 rounded p-1"
                                            onClick={() => {
                                                setWaitingDrivers(
                                                    waitingDrivers.filter(
                                                        (d) =>
                                                            d.driver._id !==
                                                            driver.driver._id
                                                    )
                                                );
                                                setDrivers([
                                                    ...drivers,
                                                    driver,
                                                ]);
                                            }}
                                        >
                                            <PlusIcon className="h-4 w-4 text-white" />
                                        </div>
                                    )}
                                    <div className="text-white">
                                        {driver.driver.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-span-9">
                    {isSuccess && (
                        <Formik
                            initialValues={{
                                drivers: drivers.map((d) => ({
                                    ...d,
                                    team: d.team?._id || d.team || "",
                                })),
                            }}
                            onSubmit={async (values) => {}}
                            validate={(values) => {
                                setDrivers(
                                    values.drivers.map((d) => ({
                                        ...d,
                                        team: teams.data.rows.find(
                                            (t) => t._id === d.team
                                        ),
                                    }))
                                );
                            }}
                            enableReinitialize
                        >
                            {({ values }) => {
                                return (
                                    <Form className="space-y-2">
                                        <div className="flex items-center grid grid-cols-3 gap-2 font-F1 text-sm text-white">
                                            <div>Pilote</div>
                                            <div>Écurie</div>
                                            <div />
                                        </div>
                                        <FieldArray
                                            name="drivers"
                                            render={(arrayHelpers) => {
                                                return values.drivers.map(
                                                    (driver, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="flex items-center grid grid-cols-3 gap-2"
                                                            >
                                                                <div
                                                                    className="text-md text-white border-l-4 pl-2"
                                                                    style={{
                                                                        borderLeftColor:
                                                                            teams.data.rows.find(
                                                                                (
                                                                                    t
                                                                                ) =>
                                                                                    t._id ===
                                                                                    driver.team
                                                                            )
                                                                                ?.color ||
                                                                            "transparent",
                                                                    }}
                                                                >
                                                                    {
                                                                        driver
                                                                            .driver
                                                                            .name
                                                                    }
                                                                </div>

                                                                <Field
                                                                    component={
                                                                        CustomField
                                                                    }
                                                                    name={`drivers.${index}.team`}
                                                                    type="select"
                                                                    disabled={
                                                                        isLoading
                                                                    }
                                                                >
                                                                    <option></option>
                                                                    {teams.data &&
                                                                        teams.data.rows.map(
                                                                            (
                                                                                team
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        key={
                                                                                            team._id
                                                                                        }
                                                                                        value={
                                                                                            team._id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            team.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Field>
                                                                <div>
                                                                    <button
                                                                        className="inline-flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                                                                        type="button"
                                                                        disabled={
                                                                            isLoading
                                                                        }
                                                                        onClick={() => {
                                                                            setWaitingDrivers(
                                                                                [
                                                                                    ...waitingDrivers,
                                                                                    ...drivers.filter(
                                                                                        (
                                                                                            d
                                                                                        ) =>
                                                                                            d
                                                                                                .driver
                                                                                                ._id ===
                                                                                            driver
                                                                                                .driver
                                                                                                ._id
                                                                                    ),
                                                                                ]
                                                                            );
                                                                            setDrivers(
                                                                                drivers.filter(
                                                                                    (
                                                                                        d
                                                                                    ) =>
                                                                                        d
                                                                                            .driver
                                                                                            ._id !==
                                                                                        driver
                                                                                            .driver
                                                                                            ._id
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            Liste
                                                                            d'attente
                                                                        </span>
                                                                        {!isLoading && (
                                                                            <ClockIcon className="h-5 w-5" />
                                                                        )}
                                                                        {isLoading && (
                                                                            <CogIcon className="h-5 w-5 animate-spin" />
                                                                        )}
                                                                    </button>
                                                                    {/*<button*/}
                                                                    {/*    className="ml-2 inline-flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"*/}
                                                                    {/*    type="button"*/}
                                                                    {/*    disabled={*/}
                                                                    {/*        isLoading*/}
                                                                    {/*    }*/}
                                                                    {/*    onClick={() => {}}*/}
                                                                    {/*>*/}
                                                                    {/*    <span>*/}
                                                                    {/*        Annuler*/}
                                                                    {/*    </span>*/}
                                                                    {/*    {!isLoading && (*/}
                                                                    {/*        <XIcon className="h-5 w-5" />*/}
                                                                    {/*    )}*/}
                                                                    {/*    {isLoading && (*/}
                                                                    {/*        <CogIcon className="h-5 w-5 animate-spin" />*/}
                                                                    {/*    )}*/}
                                                                    {/*</button>*/}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </div>
            </div>
            <div className="flex justify-end mt-12">
                <button
                    className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                        updateRace({
                            _id: raceId,
                            "drivers.active": drivers,
                            "drivers.waiting": waitingDrivers,
                        });
                    }}
                >
                    <span>Sauvegarder</span>
                    {!isLoading && <SaveIcon className="h-5 w-5" />}
                    {isLoading && <CogIcon className="h-5 w-5 animate-spin" />}
                </button>
            </div>
        </>
    );
}

export default RaceRegistrations;
