import React from "react";

function TeamCard({ position, team, drivers, points }) {
    return (
        <div
            className="flex flex-col bg-slate-700 border-l-2 rounded p-4 gap-y-4"
            style={{
                borderLeftColor: team.color,
            }}
        >
            <div
                className="flex flex-row justify-between items-center border-b pb-4"
                style={{
                    borderBottomColor: team.color,
                }}
            >
                <div className="font-F1Black text-white text-5xl">
                    {position}
                </div>
                <div className="flex flex-col text-white">
                    <span className="font-F1Wide text-right text-xl">
                        {points}
                    </span>
                    <span className="font-F1Wide text-right text-sm text-slate-300">
                        PTS
                    </span>
                </div>
            </div>
            <div
                className="flex flex-row justify-between items-center border-b pb-4"
                style={{
                    borderBottomColor: team.color,
                }}
            >
                <div
                    className="font-F1Bold text-2xl text-white border-l-8 pl-4"
                    style={{
                        borderLeftColor: team.color,
                    }}
                >
                    {team.name}
                </div>
                <img
                    className="w-10 h-10"
                    src={`/teams/icon/${team.icon}`}
                    alt=""
                />
            </div>
            <div className="flex justify-between">
                {drivers.map((driver, index) => (
                    <div
                        key={driver._id}
                        className="flex flex-row items-center"
                    >
                        {index === 0 && (
                            <img
                                className="rounded-full border-2 border-slate-500 w-10 h-10 mr-3"
                                src={
                                    driver.user?.discord?.avatar
                                        ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                        : "/avatar.png"
                                }
                                alt=""
                            />
                        )}
                        <span className="text-white font-bold text-lg">
                            {driver.name}
                        </span>
                        {index === 1 && (
                            <img
                                className="rounded-full border-2 border-slate-500 w-10 h-10 ml-3"
                                src={
                                    driver.user?.discord?.avatar
                                        ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                        : "/avatar.png"
                                }
                                alt=""
                            />
                        )}
                    </div>
                ))}
            </div>
            <div>
                <img className="m-auto" src={`/cars/${team.car}`} alt="" />
            </div>
        </div>
    );
}

export default TeamCard;
