import React, { useState } from "react";
import DriversStanding from "./DriversStanding";
import TeamsStanding from "./TeamsStanding";
import classNames from "classnames";

function Standings() {
    const [type, setType] = useState("drivers");

    return (
        <div className="mt-6">
            <div className="flex flex-row justify-around text-sm">
                <div
                    className={classNames(
                        "border-b-2 text-white flex-1 text-center pb-1  cursor-pointer",
                        {
                            "border-amber-400": type === "drivers",
                            "border-transparent hover:border-amber-400":
                                type !== "drivers",
                        }
                    )}
                    onClick={() => setType("drivers")}
                >
                    Pilotes
                </div>
                <div
                    className={classNames(
                        "border-b-2 text-white flex-1 text-center pb-1  cursor-pointer",
                        {
                            "border-amber-400": type === "teams",
                            "border-transparent hover:border-amber-400":
                                type !== "teams",
                        }
                    )}
                    onClick={() => setType("teams")}
                >
                    Écuries
                </div>
            </div>

            {type === "drivers" && <DriversStanding />}
            {type === "teams" && <TeamsStanding />}
        </div>
    );
}

export default Standings;
