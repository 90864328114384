import {
    CalendarIcon,
    HomeIcon,
    OfficeBuildingIcon,
    UsersIcon,
} from "@heroicons/react/outline";

export const adminNavLinks = [
    { name: "Dashboard", to: "/admin", icon: HomeIcon, end: true },
    { name: "Courses", to: "/admin/races", icon: CalendarIcon, end: false },
    { name: "Pilotes", to: "/admin/drivers", icon: UsersIcon, end: false },
    {
        name: "Écuries",
        to: "/admin/teams",
        icon: OfficeBuildingIcon,
        end: false,
    },
];
