import { useKeycloak } from "@react-keycloak/web";

function usePermissions(requestedRoles = []) {
    const { keycloak } = useKeycloak();
    let permissions = {};

    const roles =
        keycloak?.tokenParsed?.resource_access["f1-manager-app"]?.roles || [];

    for (const role of requestedRoles) {
        permissions[role] = roles.includes(role);
    }

    return permissions;
}

export { usePermissions };
