import React from "react";
import { useRacesQuery } from "queries/races";
import RaceCard from "pages/Public/Races/components/RaceCard";
import { NavLink } from "react-router-dom";
import {useSelector} from "react-redux";

function Races() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const races = useRacesQuery(currentSeasonId);

    return (
        <div className="w-full max-w-7xl mx-auto xl:px-8">
            <h2 className="font-F1Bold text-md lg:text-xl text-slate-300 mt-8">
                À venir
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 py-8">
                {races?.data?.rows
                    .filter((r) => r.results.race.length === 0)
                    .map((race) => (
                        <NavLink key={race._id} to={race._id}>
                            <RaceCard {...race} />
                        </NavLink>
                    ))}
            </div>
            <h2 className="font-F1Bold text-md lg:text-xl text-slate-300">
                Terminées
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 py-8">
                {races?.data?.rows
                    .filter((r) => r.results.race.length > 0)
                    .map((race) => (
                        <NavLink key={race._id} to={race._id}>
                            <RaceCard {...race} />
                        </NavLink>
                    ))}
            </div>
        </div>
    );
}

export default Races;
