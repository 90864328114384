import { useDispatch } from "react-redux";
import { setModalComponent } from "./Modal";
import { setAsideComponent } from "./Aside";
import { modalSlice } from "store/slices/modal";
import { asideSlice } from "store/slices/aside";

function useDialog() {
    const dispatch = useDispatch();

    const openModal = (component, props) => {
        setModalComponent(component);
        dispatch(modalSlice.actions.open(props));
    };
    const openAside = (component, props) => {
        setAsideComponent(component);
        dispatch(asideSlice.actions.open(props));
    };

    return {
        openModal,
        openAside,
    };
}

export { useDialog };
