import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";

function Public() {
    return (
        <div className="relative min-h-screen flex flex-col p-4">
            <Header />

            <Outlet />
        </div>
    );
}

export default Public;
