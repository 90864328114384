import { useQuery } from "react-query";
import axios from "axios";

export const useSeasonsQuery = () => {
    return useQuery(
        ["seasons"],
        async () =>
            (
                await axios.get(`/seasons`, {
                    params: {},
                })
            ).data
    );
};

export const useCurrentSeasonQuery = () => {
    return useQuery(
        ["seasons", "current"],
        async () =>
            (
                await axios.get(`/seasons`, {
                    params: {
                        query: {
                            current: true,
                        },
                    },
                })
            ).data
    );
};
