import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

function Register() {
    const { pathname } = useLocation();
    const { keycloak, initialized } = useKeycloak();
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);

    useEffect(() => {
        if (keycloak.authenticated) {
            axios.get("/me").then(({ data, status }) => {
                if (status !== 204) {
                    axios.get("/drivers/me").then(({ data, status }) => {
                        if (status !== 204) setAlreadyRegistered(true);
                    });
                }
            });
        }
    }, [keycloak, initialized]);

    if (alreadyRegistered === true)
        return (
            <div className="w-full max-w-4xl mx-auto xl:px-8 mt-16">
                <div className="bg-slate-700 rounded p-4">
                    <h1 className="text-white text-md lg:text-lg font-F1 text-center">
                        Tu es déjà inscrit pour la saison 5 de la Ligue
                        Azgharienne !
                    </h1>
                </div>
            </div>
        );
    else
        return (
            <div className="w-full max-w-4xl mx-auto xl:px-8 mt-16 mb-16">
                <div className="flex text-slate-200 font-F1 space-x-2 pl-4">
                    <div
                        className={classNames("rounded-t px-4 py-2", {
                            "bg-slate-700": pathname === "/register",
                            "hidden lg:block": pathname !== "/register",
                        })}
                    >
                        Règlement
                    </div>
                    <div
                        className={classNames("rounded-t px-4 py-2", {
                            "bg-slate-700": pathname === "/register/login",
                            "hidden lg:block": pathname !== "/register/login",
                        })}
                    >
                        Connexion
                    </div>
                    <div
                        className={classNames("rounded-t px-4 py-2", {
                            "bg-slate-700":
                                pathname === "/register/informations",
                            "hidden lg:block":
                                pathname !== "/register/informations",
                        })}
                    >
                        Informations
                    </div>
                    <div
                        className={classNames("rounded-t px-4 py-2", {
                            "bg-slate-700":
                                pathname === "/register/confirmation",
                            "hidden lg:block":
                                pathname !== "/register/confirmation",
                        })}
                    >
                        Confirmation
                    </div>
                </div>
                <div className="bg-slate-700 rounded p-4">
                    <Outlet />
                </div>
            </div>
        );
}

export default Register;
