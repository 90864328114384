import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { publicNavLinks } from "../../../routers/publicNavLinks";
import { useKeycloak } from "@react-keycloak/web";
import { useDriverMeQuery } from "queries/drivers";
import { MenuIcon } from "@heroicons/react/outline";
// import { usePermissions } from "utils/permissions";

function Header() {
    const { keycloak, initialized } = useKeycloak();
    const driverMe = useDriverMeQuery({
        enabled: initialized,
        retry: false,
    });
    // const permissions = usePermissions(["admin", "driver"]);

    return (
        <Disclosure as="nav" className="bg-slate-800">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex flex-1 justify-between">
                                <Link
                                    to="/"
                                    className="flex-shrink-0 flex items-center"
                                >
                                    <img
                                        className="block lg:h-8 h-4 w-auto"
                                        src="/F1.svg"
                                        alt="Logo"
                                    />
                                    <span className="font-F1Bold text-white uppercase ml-4 lg:text-xl text-sm">
                                        Ligue Azgharienne
                                    </span>
                                </Link>
                                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 font-F1">
                                    {publicNavLinks.map((item) => (
                                        <NavLink
                                            key={item.to}
                                            to={item.to}
                                            className={({ isActive }) =>
                                                classNames(
                                                    "inline-flex items-center px-1 pt-1 text-sm font-medium",
                                                    {
                                                        "text-red-600":
                                                            isActive,
                                                        "text-white hover:text-red-600":
                                                            !isActive,
                                                    }
                                                )
                                            }
                                        >
                                            {item.name}
                                        </NavLink>
                                    ))}

                                    {driverMe &&
                                        (driverMe.data === "" ||
                                            driverMe.isError) && (
                                            <NavLink
                                                to={"/register"}
                                                className={({ isActive }) =>
                                                    classNames(
                                                        "inline-flex items-center px-1 pt-1 text-sm font-medium",
                                                        {
                                                            "text-red-600":
                                                                isActive,
                                                            "text-white hover:text-red-600":
                                                                !isActive,
                                                        }
                                                    )
                                                }
                                            >
                                                S'inscrire
                                            </NavLink>
                                        )}

                                    <a
                                        href="https://fichiers.azgharie.net/f1/saison5.pdf"
                                        target="_blank"
                                        className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white hover:text-red-600"
                                    >
                                        Règlement
                                    </a>

                                    {/*{keycloak.authenticated &&*/}
                                    {/*    permissions["admin"] && (*/}
                                    {/*        <NavLink*/}
                                    {/*            to="/admin"*/}
                                    {/*            className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white hover:text-red-600"*/}
                                    {/*        >*/}
                                    {/*            Admin*/}
                                    {/*        </NavLink>*/}
                                    {/*    )}*/}
                                    {!keycloak.authenticated && (
                                        <span
                                            className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white hover:text-red-600 cursor-pointer"
                                            onClick={() => {
                                                keycloak.login();
                                            }}
                                        >
                                            Connexion
                                        </span>
                                    )}
                                    {keycloak.authenticated && (
                                        <span
                                            className="inline-flex items-center px-1 pt-1 text-sm font-medium text-white hover:text-red-600 cursor-pointer"
                                            onClick={() => {
                                                keycloak.logout();
                                            }}
                                        >
                                            Déconnexion
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <MenuIcon className="w-5 h-5" />
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {publicNavLinks.map((item) => (
                                <NavLink
                                    key={item.to}
                                    to={item.to}
                                    className={({ isActive }) =>
                                        classNames(
                                            "block px-1 pt-1 text-md font-medium",
                                            {
                                                "text-red-600": isActive,
                                                "text-white hover:text-red-600":
                                                    !isActive,
                                            }
                                        )
                                    }
                                >
                                    {item.name}
                                </NavLink>
                            ))}
                            {driverMe &&
                                (driverMe.data === "" || driverMe.isError) && (
                                    <NavLink
                                        to={"/register"}
                                        className={({ isActive }) =>
                                            classNames(
                                                "block px-1 pt-1 text-sm font-medium",
                                                {
                                                    "text-red-600": isActive,
                                                    "text-white hover:text-red-600":
                                                        !isActive,
                                                }
                                            )
                                        }
                                    >
                                        S'inscrire
                                    </NavLink>
                                )}

                            {!keycloak.authenticated && (
                                <span
                                    className="block px-1 pt-1 text-sm font-medium text-white hover:text-red-600 cursor-pointer"
                                    onClick={() => {
                                        keycloak.login();
                                    }}
                                >
                                    Connexion
                                </span>
                            )}
                            {keycloak.authenticated && (
                                <span
                                    className="block px-1 pt-1 text-sm font-medium text-white hover:text-red-600 cursor-pointer"
                                    onClick={() => {
                                        keycloak.logout();
                                    }}
                                >
                                    Déconnexion
                                </span>
                            )}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default Header;
