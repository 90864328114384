import React from "react";
import { useSelector } from "react-redux";
import { useTeamsQuery } from "queries/teams";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { DateTime } from "luxon";
import { DATETIME_FULL } from "luxon/src/impl/formats";

function Teams() {
    const currentSeasonId = useSelector((state) => state.admin.season);
    const teams = useTeamsQuery(currentSeasonId);
    const navigate = useNavigate();

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold">Écuries</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <table className="w-full text-white">
                    <thead className="bg-slate-700 font-F1 text-sm">
                        <tr>
                            <th className="text-left p-2 w-20" />
                            <th className="text-left p-2">Nom</th>
                            <th className="text-left p-2" />
                        </tr>
                    </thead>
                    <tbody>
                        {teams.isSuccess &&
                            teams.data &&
                            teams.data.rows.map((team) => (
                                <tr
                                    key={team._id}
                                    className="bg-slate-700 hover:bg-slate-600 cursor-pointer select-none"
                                    onClick={() => navigate(`${team._id}`)}
                                >
                                    <td className="text-left p-2">
                                        <img
                                            className="w-7 h-7"
                                            src={`/teams/icon/${team.icon}`}
                                            alt=""
                                        />
                                    </td>
                                    <td className="text-left p-2">
                                        <div
                                            className="border-l-4 pl-4"
                                            style={{
                                                borderLeftColor: team.color,
                                            }}
                                        >
                                            {team.name}
                                        </div>
                                    </td>
                                    <td>
                                        <img
                                            className="h-7"
                                            src={`/cars/${team.car}`}
                                            alt=""
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Teams;
