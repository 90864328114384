import { createSlice } from "@reduxjs/toolkit";
// import asideReducers from "store/reducers/aside";

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        season: null,
    },
    reducers: {
        setSeason: (state, action) => {
            state.season = action.payload;
        },
    },
});

export const { setSeason } = adminSlice.actions;

const adminReducer = adminSlice.reducer;

export default adminReducer;
