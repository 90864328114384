import React from "react";
import {useSelector} from "react-redux";
import DriverCard from "./components/DriverCard";
import { useDriversStandingQuery } from "../../../queries/standings";

function Drivers() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const driversStanding = useDriversStandingQuery(currentSeasonId);
    return (
        <div className="w-full max-w-7xl mx-auto xl:px-8 grid grid-cols-1 md:grid-cols-4 gap-8 py-8">
            {driversStanding.data?.map(({ driver, points }, key) => {
                return (
                    <DriverCard
                        key={driver._id}
                        position={key + 1}
                        driver={driver}
                        points={points}
                    />
                );
            })}
        </div>
    );
}

export default Drivers;
