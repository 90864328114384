import React from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useRaceQuery } from "queries/races";
import ReactCountryFlag from "react-country-flag";
import classNames from "classnames";

function Race() {
    const { raceId } = useParams();
    const race = useRaceQuery(raceId, { populate: "circuit" });

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold flex items-center">
                    <ReactCountryFlag
                        countryCode={race?.data?.circuit.country}
                        svg
                        className="mr-4"
                        style={{
                            width: "1.25em",
                            height: "1.25em",
                        }}
                    />
                    <div>{race?.data?.name}</div>
                </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <div className="flex text-slate-200 font-F1 space-x-2 pl-4">
                    <NavLink
                        to={`/admin/races/${raceId}`}
                        className={({ isActive }) =>
                            classNames(
                                "rounded-t px-4 py-2 hover:bg-slate-700",
                                {
                                    "bg-slate-700": isActive,
                                }
                            )
                        }
                        end
                    >
                        Informations
                    </NavLink>
                    <NavLink
                        to={`registrations`}
                        className={({ isActive }) =>
                            classNames(
                                "rounded-t px-4 py-2 hover:bg-slate-700",
                                {
                                    "bg-slate-700": isActive,
                                }
                            )
                        }
                        end
                    >
                        Inscriptions
                    </NavLink>
                    <NavLink
                        to={`qualifying`}
                        className={({ isActive }) =>
                            classNames(
                                "rounded-t px-4 py-2 hover:bg-slate-700",
                                {
                                    "bg-slate-700": isActive,
                                }
                            )
                        }
                        end
                    >
                        Qualifications
                    </NavLink>
                    <NavLink
                        to={`race`}
                        className={({ isActive }) =>
                            classNames(
                                "rounded-t px-4 py-2 hover:bg-slate-700",
                                {
                                    "bg-slate-700": isActive,
                                }
                            )
                        }
                        end
                    >
                        Course
                    </NavLink>
                </div>
                <div className="bg-slate-700 rounded p-4">
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default Race;
