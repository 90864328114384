import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { useDriversQuery } from "queries/drivers";

export const useTeamsStandingQuery = (season) => {
    const [res, setRes] = useState([]);

    const drivers = useDriversQuery(season, { registration: "accepted" });

    const teams = useQuery(
        ["teams", season],
        async () =>
            (
                await axios.get(`/teams`, {
                    params: {
                        query: {
                            season
                        },
                        sort: "name",
                    },
                })
            ).data,
        {
            enabled: drivers.isSuccess,
        }
    );

    const standing = useQuery(
        ["standings", season, "teams"],
        async () => (await axios.get(`/standings/${season}/teams`)).data,
        {
            enabled: teams.isSuccess,
            onSuccess: (data) => {
                for (const team of teams.data.rows) {
                    const teamIndex = data.findIndex((t) => t._id === team._id);
                    if (teamIndex !== -1)
                        data[teamIndex].drivers = drivers.data.rows.filter(
                            (d) => d.team?._id === team._id
                        );

                    if (!data.find((d) => team._id === d.team._id)) {
                        data.push({
                            points: 0,
                            team,
                            drivers:
                                teamIndex !== -1
                                    ? (data[teamIndex].drivers =
                                          drivers.data.rows.filter(
                                              (d) => d.team?._id === team._id
                                          ))
                                    : drivers.data.rows.filter(
                                          (d) => d.team?._id === team._id
                                      ),
                        });
                    }
                }
                setRes(data);
            },
        }
    );
    return {
        ...standing,
        data: res,
    };
};
export const useDriversStandingQuery = (season) => {
    const [res, setRes] = useState([]);

    const drivers = useQuery(
        ["drivers"],
        async () =>
            (
                await axios.get(`/drivers`, {
                    params: {
                        query: {
                            registration: "accepted",
                            season
                        },
                        sort: "name",
                        populate: "user, team",
                        pageSize: 50,
                    },
                })
            ).data
    );

    const standing = useQuery(
        ["standings", season, "drivers"],
        async () => (await axios.get(`/standings/${season}/drivers`)).data,
        {
            enabled: drivers.isSuccess,
            onSuccess: (data) => {
                for (const driver of drivers.data.rows) {
                    const driverIndex = data.findIndex(
                        (d) => d.driver._id === driver._id
                    );
                    if (driverIndex !== -1) {
                        data[driverIndex].driver = {
                            ...data[driverIndex].driver,
                            ...driver,
                        };
                    }

                    if (!data.find((d) => driver._id === d.driver._id)) {
                        data.push({
                            points: 0,
                            driver,
                        });
                    }
                }
                setRes(data);
            },
        }
    );

    return {
        ...standing,
        data: res,
    };
};

export const useTeamsStandingByRacesQuery = (season) => {
    const [res, setRes] = useState([]);

    const drivers = useQuery(
        ["drivers"],
        async () =>
            (
                await axios.get(`/drivers`, {
                    params: {
                        query: {
                            registration: "accepted",
                            season
                        },
                        sort: "name",
                        populate: "user, team",
                        pageSize: 50,
                    },
                })
            ).data
    );

    const races = useQuery(
        ["races"],
        async () =>
            (
                await axios.get(`/races`, {
                    params: {
                        query: {
                            season,
                        },
                        sort: "position",
                        pageSize: 25,
                    },
                })
            ).data
    );

    const standing = useQuery(
        ["standings", season, "drivers", "races"],
        async () =>
            (await axios.get(`/standings/${season}/drivers/races`)).data,
        {
            enabled: drivers.isSuccess && races.isSuccess,
            onSuccess: (data) => {
                for (const driver of drivers.data.rows) {
                    if (!data.find((d) => driver._id === d.driver._id)) {
                        data.push({
                            driver,
                            races: [],
                            totalPoints: 0,
                        });
                    }
                }

                for (const race of races.data.rows) {
                    for (const driver of data) {
                        if (!driver.races.find((r) => r._id === race._id)) {
                            driver.races.push({
                                ...race,
                                points: 0,
                            });
                        }
                    }
                }

                setRes(data);
            },
        }
    );

    return {
        ...standing,
        data: res,
    };
};
