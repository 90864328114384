import React from "react";
import { useRacesQuery } from "queries/races";
import ReactCountryFlag from "react-country-flag";
import { DateTime } from "luxon";
import { DATETIME_FULL } from "luxon/src/impl/formats";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Races() {
    const currentSeasonId = useSelector((state) => state.admin.season);
    const races = useRacesQuery(currentSeasonId);
    const navigate = useNavigate();

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold">Courses</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <table className="w-full text-white">
                    <thead className="bg-slate-700 font-F1 text-sm">
                        <tr>
                            <th className="text-left p-2">Course</th>
                            <th className="text-left p-2">Circuit</th>
                            <th className="text-left p-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {races.isSuccess &&
                            races.data &&
                            races.data.rows.map((race) => (
                                <tr
                                    key={race._id}
                                    className="even:bg-slate-700 hover:bg-slate-600 cursor-pointer select-none"
                                    onClick={() => navigate(`${race._id}`)}
                                >
                                    <td className="text-left p-2">
                                        <ReactCountryFlag
                                            countryCode={race.circuit.country}
                                            svg
                                            className="mr-2"
                                            style={{
                                                width: "1.25em",
                                                height: "1.25em",
                                            }}
                                        />
                                        {race.name}
                                    </td>
                                    <td className="text-left p-2">
                                        {race.circuit.name}
                                    </td>
                                    <td className="text-left p-2">
                                        {race.date
                                            ? DateTime.fromISO(
                                                  race.date
                                              ).toLocaleString(DATETIME_FULL)
                                            : "Non défini"}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Races;
