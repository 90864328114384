import React from "react";
import { DateTime } from "luxon";
import ReactCountryFlag from "react-country-flag";

function RaceHeader({ name, shortName, circuit, date }) {
    return (
        <div className="mt-4 text-center">
            <div className="flex items-center justify-center">
                <ReactCountryFlag
                    countryCode={circuit?.country}
                    svg
                    style={{
                        width: "3em",
                        height: "3em",
                    }}
                    className="mr-4"
                />

                <h1 className="font-F1Bold text-xl lg:text-4xl text-white">
                    {name}
                </h1>
            </div>
            <div className="font-F1 text-md lg:text-xl text-slate-300 mt-2">
                {date
                    ? DateTime.fromISO(date)
                          .setLocale("fr-FR")
                          .toFormat("dd MMMM yyyy")
                    : "Date à définir"}
            </div>
        </div>
    );
}

export default RaceHeader;
