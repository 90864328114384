import React from "react";
import { useDriversQuery } from "queries/drivers";
import { useSelector } from "react-redux";
import { useNextRacesQuery } from "queries/races";
import InfosCard from "pages/Admin/Dashboard/components/InfosCard";
import { NavLink } from "react-router-dom";
import RaceCard from "pages/Public/Races/components/RaceCard";

function Index() {
    const currentSeasonId = useSelector((state) => state.admin.season);

    const drivers = useDriversQuery(currentSeasonId, {
        registration: "accepted",
    });
    const pendingDrivers = useDriversQuery(currentSeasonId, {
        registration: "pending",
    });

    const nextRaces = useNextRacesQuery(currentSeasonId, { pageSize: 2 });

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold">Dashboard</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="grid grid-cols-4 mt-2 gap-4">
                    <InfosCard
                        title={"Pilotes"}
                        value={drivers?.data?.rows?.length}
                    />
                    <InfosCard
                        title={"Inscriptions en attente"}
                        value={pendingDrivers?.data?.rows?.length}
                    />
                </div>
                <h2 className="text-lg text-slate-300 font-F1Bold mt-4">
                    Prochaines courses
                </h2>
                <div className="grid grid-cols-4 mt-2 gap-4">
                    {nextRaces?.data?.map((race) => (
                        <NavLink key={race._id} to={`/admin/races/${race._id}`}>
                            <RaceCard {...race} />
                        </NavLink>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Index;
