import React from "react";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import CustomField from "components/CustomField";
import { CogIcon, SaveIcon } from "@heroicons/react/outline";
import { useDriverQuery, useUpdateRaceMutation } from "queries/drivers";
import { useTeamsQuery } from "queries/teams";
import { useSelector } from "react-redux";

function Driver() {
    const currentSeasonId = useSelector((state) => state.admin.season);

    const { driverId } = useParams();
    const driver = useDriverQuery(driverId, { populate: "user, team" });
    const teams = useTeamsQuery(currentSeasonId);
    const { mutate: updateDriver, isLoading } = useUpdateRaceMutation();

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold">
                    <div
                        className="border-l-4 pl-4 flex items-center"
                        style={{
                            borderLeftColor: driver?.data?.team?.color,
                        }}
                    >
                        <img
                            className="rounded-full border-2 border-slate-500 w-10 h-10 mr-2"
                            src={
                                driver.user?.discord?.avatar
                                    ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                                    : "/avatar.png"
                            }
                            alt=""
                        />
                        {driver?.data?.name}
                    </div>
                </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <div className="flex text-slate-200 font-F1 space-x-2 pl-4">
                    <div className="rounded-t px-4 py-2 hover:bg-slate-700 bg-slate-700">
                        Informations
                    </div>
                </div>
                <div className="bg-slate-700 rounded p-4">
                    {driver.data && (
                        <Formik
                            initialValues={{
                                name: driver.data.name,
                                raceNumber: driver.data.raceNumber,
                                team: driver.data.team,
                                registration: driver.data.registration,
                            }}
                            onSubmit={async (values) =>
                                updateDriver({
                                    _id: driverId,
                                    ...values,
                                })
                            }
                        >
                            <Form>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <Field
                                        component={CustomField}
                                        name="name"
                                        label="Nom"
                                        disabled={isLoading}
                                    />
                                    <Field
                                        component={CustomField}
                                        name="raceNumber"
                                        label="Numéro de voiture"
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <Field
                                        component={CustomField}
                                        type="select"
                                        name="registration"
                                        label="Inscription"
                                        disabled={isLoading}
                                    >
                                        <option value="pending">
                                            En attente
                                        </option>
                                        <option value="accepted">
                                            Accepté
                                        </option>
                                        <option value="refused">Refusé</option>
                                    </Field>
                                    <Field
                                        component={CustomField}
                                        name="team._id"
                                        type="select"
                                        label="Écurie"
                                        disabled={isLoading}
                                    >
                                        <option></option>
                                        {teams.data &&
                                            teams.data.rows.map((team) => {
                                                return (
                                                    <option
                                                        key={team._id}
                                                        value={team._id}
                                                    >
                                                        {team.name}
                                                    </option>
                                                );
                                            })}
                                    </Field>
                                </div>
                                <div className="flex justify-end mt-12">
                                    <button
                                        className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <span>Sauvegarder</span>
                                        {!isLoading && (
                                            <SaveIcon className="h-5 w-5" />
                                        )}
                                        {isLoading && (
                                            <CogIcon className="h-5 w-5 animate-spin" />
                                        )}
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    )}
                </div>
            </div>
        </>
    );
}

export default Driver;
