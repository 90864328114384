import React from "react";
import { DateTime } from "luxon";

function RaceDrivers({ active, waiting }) {
    return (
        <>
            <div className="mt-8">
                <h2 className="font-F1Bold text-md lg:text-xl text-slate-300">
                    Participants ({active.length})
                </h2>
                {active.length === 0 && (
                    <div className="mt-4 text-white">Aucun participant</div>
                )}
                {active.length > 0 && (
                    <div className="grid lg:grid-cols-2 mt-2 mb-2 gap-x-4">
                        <div>
                            {active.slice(0, 10).map((a) => (
                                <Driver key={a.driver._id} {...a} />
                            ))}
                        </div>
                        <div>
                            {active.slice(10).map((a) => (
                                <Driver key={a.driver._id} {...a} />
                            ))}
                        </div>
                    </div>
                )}
                {waiting.length > 0 && (
                    <>
                        <h2 className="font-F1Bold text-md lg:text-xl text-slate-300">
                            Liste d'attente ({waiting.length})
                        </h2>
                        <div className="grid lg:grid-cols-2 mt-2 mb-2 gap-x-4">
                            <div>
                                {waiting.slice(0, 10).map((w) => (
                                    <Driver key={w.driver._id} {...w} />
                                ))}
                            </div>
                            <div>
                                {waiting.slice(10).map((w) => (
                                    <Driver key={w.driver._id} {...w} />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

function Driver({ driver, team, date }) {
    return (
        <div className="grid grid-cols-3 p-1 odd:bg-slate-700 rounded">
            <div className="flex items-center text-white">
                <img
                    className="rounded-full h-7 w-7 border-2 border-slate-500 mr-2"
                    src={
                        driver.user?.discord?.avatar
                            ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                            : "/avatar.png"
                    }
                    alt=""
                />
                {driver.name}
            </div>
            <div className="flex items-center">
                <span
                    className="border-l-4 pl-2 text-slate-300"
                    style={{
                        borderLeftColor: team?.color,
                    }}
                >
                    {team?.name}
                </span>
            </div>
            <div className="flex items-center text-slate-400">
                {DateTime.fromISO(date)
                    .setLocale("fr-FR")
                    .toFormat("dd/MM/yyyy HH:mm:ss")}
            </div>
        </div>
    );
}

export default RaceDrivers;
