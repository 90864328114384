import React from "react";
import { useParams } from "react-router-dom";
import { useRaceQuery, useUpdateRaceMutation } from "queries/races";
import { Formik, Field, Form } from "formik";
import { useCircuitsQuery } from "queries/circuits";
import { CogIcon, SaveIcon } from "@heroicons/react/outline";
import CustomField from "components/CustomField";

function RaceInformations() {
    const { raceId } = useParams();

    const race = useRaceQuery(raceId);
    const { mutate: updateRace, isLoading } = useUpdateRaceMutation();

    const circuits = useCircuitsQuery({
        pageSize: 50,
    });

    return (
        <div>
            {race.data && (
                <Formik
                    initialValues={{
                        name: race.data.name,
                        shortName: race.data.shortName,
                        code: race.data.code,
                        circuit: race.data.circuit,
                        date: race.data.date,
                        registrationOpened: race.data.registrationOpened,
                    }}
                    onSubmit={async (values) =>
                        updateRace({
                            _id: raceId,
                            ...values,
                        })
                    }
                >
                    <Form>
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            <Field
                                component={CustomField}
                                name="name"
                                label="Nom"
                                disabled={isLoading}
                            />
                            <Field
                                component={CustomField}
                                name="shortName"
                                label="Nom court"
                                disabled={isLoading}
                            />
                            <Field
                                component={CustomField}
                                name="code"
                                label="Code"
                                disabled={isLoading}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <Field
                                component={CustomField}
                                name="circuit"
                                type="select"
                                label="Circuit"
                                disabled={isLoading}
                            >
                                {circuits.data &&
                                    circuits.data.rows.map((circuit) => {
                                        return (
                                            <option
                                                key={circuit._id}
                                                value={circuit._id}
                                            >
                                                {circuit.name} - {circuit.place}
                                            </option>
                                        );
                                    })}
                            </Field>
                            <Field
                                component={CustomField}
                                name="date"
                                type="datetime"
                                label="Date"
                                disabled={isLoading}
                            />
                        </div>
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            <div>
                                <label
                                    className="block text-white text-md font-bold"
                                    htmlFor="registrationOpened"
                                >
                                    Ouverture des inscriptions
                                </label>
                                <Field
                                    id="registrationOpened"
                                    name="registrationOpened"
                                    type="checkbox"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-12">
                            <button
                                className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                                type="submit"
                                disabled={isLoading}
                            >
                                <span>Sauvegarder</span>
                                {!isLoading && <SaveIcon className="h-5 w-5" />}
                                {isLoading && (
                                    <CogIcon className="h-5 w-5 animate-spin" />
                                )}
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

export default RaceInformations;
