import React, { useEffect } from "react";

import classNames from "classnames";
import { adminNavLinks } from "routers/adminNavLinks";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useCurrentSeasonQuery, useSeasonsQuery } from "queries/seasons";
import { useDispatch, useSelector } from "react-redux";
import { setSeason } from "store/slices/admin";
import { useKeycloak } from "@react-keycloak/web";

function Sidebar() {
    const { keycloak } = useKeycloak();
    const seasons = useSeasonsQuery();
    const currentSeason = useCurrentSeasonQuery();
    const currentSeasonId = useSelector((state) => state.admin.season);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentSeason.isSuccess && currentSeason.data && !currentSeasonId) {
            dispatch(setSeason(currentSeason.data?.rows[0]?._id));
        }
    }, [currentSeason, currentSeasonId, dispatch]);

    const user = keycloak.tokenParsed;

    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-slate-700 rounded-r-2xl">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 px-4">
                        <img className="h-4 w-auto" src="/F1.svg" alt="Logo" />
                        <span className="font-F1Bold text-white uppercase ml-4 text-sm">
                            Ligue Azgharienne
                        </span>
                    </div>
                    <div className="px-2 mt-3 mb-1">
                        <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded p-2 bg-slate-600 text-white focus:outline-none"
                            value={currentSeasonId || ""}
                            onChange={(ev) => {
                                dispatch(setSeason(ev.target.value));
                            }}
                        >
                            <option />
                            {seasons.isSuccess &&
                                seasons.data &&
                                seasons.data.rows.map((season) => (
                                    <option key={season._id} value={season._id}>
                                        {season.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <nav className="mt-2 flex-1 px-2 space-y-1">
                        {adminNavLinks.map((item) => (
                            <NavLink
                                key={item.to}
                                to={item.to}
                                className={({ isActive }) =>
                                    classNames(
                                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                                        {
                                            "bg-slate-800 text-white": isActive,
                                            "text-slate-300 hover:bg-slate-600 hover:text-white":
                                                !isActive,
                                        }
                                    )
                                }
                                end={item.end}
                            >
                                <item.icon
                                    className={classNames(
                                        "mr-3 flex-shrink-0 h-6 w-6",
                                        {
                                            "text-slate-300": item.current,
                                            "text-slate-400 group-hover:text-slate-300":
                                                !item.current,
                                        }
                                    )}
                                    aria-hidden="true"
                                />
                                {item.name}
                            </NavLink>
                        ))}
                    </nav>
                    <nav className="px-2">
                        <NavLink
                            to="/"
                            className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-slate-300 hover:bg-slate-600 hover:text-white "
                        >
                            <ArrowLeftIcon className="mr-3 flex-shrink-0 h-6 w-6 text-slate-400 group-hover:text-slate-300" />
                            Retour au site
                        </NavLink>
                    </nav>
                </div>
                <div className="flex-shrink-0 flex bg-slate-600 p-4 rounded-br-2xl">
                    <div className="flex items-center">
                        <div>
                            <img
                                className="inline-block h-9 w-9 rounded-full"
                                src={`https://cdn.discordapp.com/avatars/${user.discord.id}/${user.discord.avatar}.png`}
                                alt=""
                            />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-white">
                                {user.discord.username}
                            </p>

                            <p
                                className="text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer"
                                onClick={() => {
                                    keycloak.logout({
                                        redirectUri:
                                            process.env.NODE_ENV ===
                                            "development"
                                                ? "http://localhost:4000"
                                                : "https://liguef1.azgharie.net",
                                    });
                                }}
                            >
                                Se déconnecter
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
