import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

function RegisterRegulation() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col gap-y-4">
            <h1 className="text-center text-white font-F1Bold text-lg">
                Règlement de la saison 5 de la Ligue Azgharienne
            </h1>
            <div className="text-white text-left">
                <h2 className="text-lg font-bold mb-4">
                    Qui peut participer ?
                </h2>
                <p className="mb-4">
                    Avant toute chose, merci de prendre connaissances des règles
                    :
                </p>
                <a
                    href="https://fichiers.azgharie.net/f1/saison5.pdf"
                    target="_blank"
                    className="inline-block px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500 mb-4"
                >
                    Accéder au règlement
                </a>
                <p className="mb-4">
                    Nos courses ont généralement lieu le vendredi soir et se
                    déroulent dans un état d'esprit convivial et respectueux.
                </p>
                <p className="mb-4">
                    Si vous souhaitez intégrer la saison et que vous êtes
                    nouveau dans l’Azgharie, merci de rédiger une présentation
                    motivant votre candidature (état d’esprit & maturité) dans
                    le salon « nouveau-pilote » sur Discord. La décision de vous
                    intégrer dépendra du staff et de l’évolution de la saison en
                    cours.
                </p>
                <p className="mb-4">
                    Si vous n’êtes pas nouveau dans l’Azgharie, vous pouvez
                    directement vous inscrire sur le site internet.
                </p>
                <p className="mb-4">
                    Dans les 2 cas, votre compte devra être validé par un
                    administrateur. Soyez donc patients.
                </p>
                <p className="mb-4">
                    Au plaisir de vous croiser sur nos circuits !
                </p>
            </div>
            <button
                type="button"
                className="self-center flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500"
                onClick={() => {
                    navigate("login", { replace: true });
                }}
            >
                J'accepte
                <CheckCircleIcon
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                />
            </button>
        </div>
    );
}

export default RegisterRegulation;
