const open = (state, action) => {
    state.opened = true;
    state.props = action.payload;
};

const close = (state) => {
    state.opened = false;
};

const clear = (state) => {
    state.props = {};
};

const modalReducers = {
    open,
    close,
    clear,
};

export default modalReducers;
