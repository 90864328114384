import { Field } from "formik";
import DateTimePicker from "react-datetime-picker";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import InputMask from "react-input-mask";
import { DateTime } from "luxon";
import React from "react";

function CustomField({
    form,
    field,
    label,
    type,
    children,
    disabled,
    className,
    mask,
    ...props
}) {
    const baseClassName =
        "block w-full shadow-sm rounded px-3 py-2 bg-slate-600 text-white focus:outline-none focus:bg-slate-500 disabled:opacity-70";
    return (
        <div className={className}>
            {label && (
                <label className="block text-white text-md font-bold">
                    {label}
                </label>
            )}
            {type !== "datetime" &&
                type !== "select" &&
                type !== "duration" &&
                (mask ? (
                    <InputMask
                        mask={mask}
                        {...field}
                        className={baseClassName}
                    />
                ) : (
                    <Field
                        {...field}
                        type={type}
                        className={baseClassName}
                        disabled={disabled}
                    >
                        {children}
                    </Field>
                ))}

            {type === "duration" && (
                <InputMask
                    mask={mask || "9:99.999"}
                    {...field}
                    className={baseClassName}
                />
            )}

            {type === "select" && (
                <Field
                    {...field}
                    as={type}
                    className={baseClassName}
                    disabled={disabled}
                >
                    {children}
                </Field>
            )}

            {type === "datetime" && (
                <DateTimePicker
                    {...field}
                    className={baseClassName}
                    calendarClassName="text-black"
                    calendarIcon={
                        <CalendarIcon className="h-5 w-5 text-white" />
                    }
                    clearIcon={<XIcon className="h-5 w-5 text-white" />}
                    onChange={(value) =>
                        form.setFieldValue(
                            field.name,
                            DateTime.fromJSDate(value).toJSON()
                        )
                    }
                    value={
                        field.value
                            ? DateTime.fromISO(field.value).toJSDate()
                            : ""
                    }
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default CustomField;
