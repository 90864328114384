import React from "react";
import { useParams } from "react-router-dom";
import { useTeamQuery, useUpdateTeamMutation } from "queries/teams";
import { Field, Form, Formik } from "formik";
import CustomField from "components/CustomField";
import { CogIcon, SaveIcon } from "@heroicons/react/outline";

function Team() {
    const { teamId } = useParams();
    const team = useTeamQuery(teamId);
    const { mutate: updateTeam, isLoading } = useUpdateTeamMutation();

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl text-white font-F1Bold flex items-center">
                    <img
                        className="w-10 h-10 mr-4"
                        src={`/teams/icon/${team?.data?.icon}`}
                        alt=""
                    />
                    <div
                        className="border-l-4 pl-4"
                        style={{
                            borderLeftColor: team?.data?.color,
                        }}
                    >
                        {team?.data?.name}
                    </div>
                </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-8">
                <div className="flex text-slate-200 font-F1 space-x-2 pl-4">
                    <div className="rounded-t px-4 py-2 hover:bg-slate-700 bg-slate-700">
                        Informations
                    </div>
                </div>
                <div className="bg-slate-700 rounded p-4">
                    {team.data && (
                        <Formik
                            initialValues={{
                                name: team.data.name,
                                color: team.data.color,
                                icon: team.data.icon,
                                logo: team.data.logo,
                                car: team.data.car,
                                teamId: team.data.teamId,
                            }}
                            onSubmit={async (values) =>
                                updateTeam({
                                    _id: teamId,
                                    ...values,
                                })
                            }
                        >
                            <Form>
                                <div className="grid grid-cols-3 gap-4 mb-4">
                                    <Field
                                        component={CustomField}
                                        name="name"
                                        label="Nom"
                                        disabled={isLoading}
                                    />
                                    <Field
                                        component={CustomField}
                                        name="color"
                                        label="Couleur"
                                        disabled={isLoading}
                                    />
                                    <Field
                                        component={CustomField}
                                        name="teamId"
                                        label="Identifiant"
                                        disabled={true}
                                    />
                                </div>
                                <div className="grid grid-cols-3 gap-4 mb-4">
                                    <Field
                                        component={CustomField}
                                        name="icon"
                                        label="Icône"
                                        disabled={isLoading}
                                    />
                                    <Field
                                        component={CustomField}
                                        name="logo"
                                        label="Logo"
                                        disabled={isLoading}
                                    />
                                    <Field
                                        component={CustomField}
                                        name="car"
                                        label="Voiture"
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className="flex justify-end mt-12">
                                    <button
                                        className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <span>Sauvegarder</span>
                                        {!isLoading && (
                                            <SaveIcon className="h-5 w-5" />
                                        )}
                                        {isLoading && (
                                            <CogIcon className="h-5 w-5 animate-spin" />
                                        )}
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    )}
                </div>
            </div>
        </>
    );
}

export default Team;
