import Home from "pages/Public/Home";
import Teams from "pages/Public/Teams";
import Races from "pages/Public/Races";
import Race from "pages/Public/Races/Race";
import Drivers from "../pages/Public/Drivers";
import Standings from "../pages/Public/Standings";

import Register from "pages/Public/Register";
import RegisterRegulation from "pages/Public/Register/RegisterRegulation";
import RegisterLogin from "pages/Public/Register/RegisterLogin";
import RegisterInformations from "pages/Public/Register/RegisterInformations";
import RegisterConfirmation from "pages/Public/Register/RegisterConfirmation";

export const publicRoutes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: "races",
        element: <Races />,
    },
    {
        path: "races/:raceId",
        element: <Race />,
    },
    {
        path: "standings",
        element: <Standings />,
    },
    {
        path: "drivers",
        element: <Drivers />,
    },
    {
        path: "teams",
        element: <Teams />,
    },
    {
        path: "register",
        element: <Register />,
        routes: [
            {
                index: true,
                element: <RegisterRegulation />,
            },
            {
                path: "login",
                element: <RegisterLogin />,
            },
            {
                path: "informations",
                element: <RegisterInformations />,
            },
            {
                path: "confirmation",
                element: <RegisterConfirmation />,
            },
        ],
    },
];
