import React from "react";

function RaceCircuit({ circuit }) {
    return (
        <div className="mt-8">
            <h2 className="font-F1Bold text-md lg:text-xl text-slate-300">
                {circuit?.name} - {circuit?.place}
            </h2>
            <div className="flex flex-col lg:flex-row mt-8">
                <img
                    src={`/circuits/${circuit.picture}.png`}
                    alt={circuit.name}
                    className="w-full lg:w-7/12"
                />
                <div className="flex-1 grid grid-cols-2 gap-4 ml-8">
                    {circuit.statistics?.firstRaceYear && (
                        <div>
                            <h3 className="font-F1 text-xs text-slate-300">
                                Premier Grand-Prix
                            </h3>
                            <div className="font-F1Bold text-2xl text-white">
                                {circuit.statistics.firstRaceYear}
                            </div>
                        </div>
                    )}
                    {circuit.statistics?.length && (
                        <div>
                            <h3 className="font-F1 text-xs text-slate-300">
                                Taille du circuit
                            </h3>
                            <div className="font-F1Bold text-2xl text-white">
                                {circuit.statistics.length}
                                <span className="font-F1 text-xs">km</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RaceCircuit;
