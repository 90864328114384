import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { MenuIcon } from "@heroicons/react/outline";
import Sidebar from "pages/Admin/components/Sidebar";
import { usePermissions } from "utils/permissions";

function Admin() {
    const { keycloak, initialized } = useKeycloak();
    const permissions = usePermissions(["admin"]);

    useEffect(() => {
        if (initialized && !keycloak.authenticated) {
            keycloak.login({ redirectUri: window.location.href });
        }
    }, [initialized, keycloak]);

    if (!initialized) return <div>Chargement en cours ...</div>;
    else if (!permissions["admin"]) return <Navigate to="/" replace />;
    else
        return (
            <div>
                <Sidebar />
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            // onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <main className="flex-1">
                        <div className="py-6">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        );
}

export default Admin;
