import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "store/slices/modal";
import asideReducer from "store/slices/aside";
import adminReducer from "store/slices/admin";
import publicReducer from "store/slices/public";

export default configureStore({
    reducer: {
        modal: modalReducer,
        aside: asideReducer,
        admin: adminReducer,
        public: publicReducer
    },
});
