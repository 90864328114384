import React from "react";
import { DateTime } from "luxon";
import ReactCountryFlag from "react-country-flag";
import classNames from "classnames";

function RaceCard({ title, value }) {
    return (
        <div className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded p-4">
            <div className="font-F1 text-white text-slate-300">{title}</div>
            <div className="font-F1Bold text-white text-lg">{value}</div>
        </div>
    );
}

export default RaceCard;
