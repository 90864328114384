import React from "react";

import Standings from "./components/Standings";
import NextRace from "./components/NextRace";
import LastRacesResults from "./components/LastRacesResults";

function Home() {
    return (
        <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
            <div className="lg:min-w-0 lg:flex-1">
                <div className="h-full py-6 pr-4 sm:pr-6 lg:pr-8">
                    <div className="h-full relative">
                        <LastRacesResults />
                    </div>
                </div>
            </div>

            <div className="pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 xl:pr-0">
                <div className="h-full pl-6 py-6 lg:w-80">
                    <div className="h-full relative">
                        <NextRace />
                        <Standings />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
