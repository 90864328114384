const open = (state, action) => {
  state.opened = true;
  state.props = action.payload;
};

const close = (state) => {
  state.opened = false;
};

const clear = (state) => {
  state.props = {};
};

const asideReducers = {
  open,
  close,
  clear,
};

export default asideReducers;
