import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { useDispatch, useSelector } from "react-redux";
import { close, clear } from "store/slices/aside";

let AsideComponent = null;

function Aside() {
    const opened = useSelector((state) => state.aside.opened);
    const props = useSelector((state) => state.aside.props);
    const dispatch = useDispatch();

    return (
        <Transition.Root
            show={opened}
            as={Fragment}
            afterLeave={() => {
                if (AsideComponent) {
                    dispatch(clear());
                    setAsideComponent(null);
                }
            }}
        >
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden"
                onClose={() => dispatch(close())}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-slate-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-md">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-300 hover:text-white"
                                            onClick={() => dispatch(close())}
                                        >
                                            <span className="sr-only">
                                                Close panel
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-full flex flex-col py-6 bg-slate-800 shadow-xl overflow-y-scroll border-l-2 border-l-slate-700">
                                    {AsideComponent && (
                                        <AsideComponent
                                            close={() => dispatch(close())}
                                            {...props}
                                        />
                                    )}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export const setAsideComponent = (component) => (AsideComponent = component);

export default Aside;
