import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

export const useNextRaceQuery = (season) => {
    return useQuery(
        ["nextRace", season],
        async () =>
            (
                await axios.get(`/races`, {
                    params: {
                        query: {
                            "results.race.0": { $exists: false },
                            season
                        },
                        sort: "position",
                        populate: "circuit",
                        pageSize: 1,
                    },
                })
            ).data.rows[0]
    );
};

export const useNextRacesQuery = (season, params) => {
    return useQuery(
        ["nextRaces", season, params],
        async () =>
            (
                await axios.get(`/races`, {
                    params: {
                        query: {
                            "results.race.0": { $exists: false },
                            season
                        },
                        sort: "position",
                        populate: "circuit",
                        pageSize: 1,
                        ...params,
                    },
                })
            ).data.rows
    );
};

export const useLastRacesResultsQuery = (season) => {
    return useQuery(
        ["lastRacesResults", season],
        async () =>
            (
                await axios.get(`/races`, {
                    params: {
                        query: {
                            "results.race.0": { $exists: true },
                            season
                        },
                        sort: "-position",
                        populate: "circuit, driver, team",
                        pageSize: 2,
                    },
                })
            ).data
    );
};

export const useRacesQuery = (season) => {
    return useQuery(
        ["races", season],
        async () =>
            (
                await axios.get(`/races`, {
                    params: {
                        query: {
                            season,
                        },
                        populate: "circuit, driver, team",
                        sort: "position",
                        pageSize: 25,
                    },
                })
            ).data
    );
};

export const useRaceQuery = (raceId, params, options) => {
    return useQuery(
        [...["race", raceId], ...(params ? [params] : [])],
        async () =>
            (
                await axios.get(`/races/${raceId}`, {
                    params: params,
                })
            ).data,
        options
    );
};

export const useUpdateRaceMutation = (options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return axios.put(`/races/${values._id}`, values);
        },
        options || {
            onSuccess: async (result, values) => {
                await queryClient.invalidateQueries(["race", values._id]);
                await queryClient.invalidateQueries(["races"]);
            },
        }
    );
};

export const useRegisterRaceMutation = (options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return axios.post(`/races/${values._id}/register`, values);
        },
        options || {
            onSuccess: async (result, values) => {
                await queryClient.invalidateQueries(["race", values._id]);
                await queryClient.invalidateQueries(["races"]);
            },
        }
    );
};

export const useUnregisterRaceMutation = (options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return axios.post(`/races/${values._id}/unregister`, values);
        },
        options || {
            onSuccess: async (result, values) => {
                await queryClient.invalidateQueries(["race", values._id]);
                await queryClient.invalidateQueries(["races"]);
            },
        }
    );
};
