import React from "react";
import { Dialog } from "@headlessui/react";
import ReactCountryFlag from "react-country-flag";
import { useRaceQuery } from "queries/races";
import { Duration } from "luxon";
import { groupBy } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";

function RaceStandingAside({ close, raceId }) {
    const race = useRaceQuery(raceId, {
        populate: "driver, team",
    });

    return (
        <>
            <div className="px-4 sm:px-6">
                <Dialog.Title className="text-white font-F1Bold uppercase">
                    <div className="flex items-center ">
                        <ReactCountryFlag
                            countryCode={race.data?.circuit.country}
                            svg
                            className="rounded mr-2"
                            style={{
                                width: "1.5em",
                                height: "1.5em",
                            }}
                        />
                        <div className="text-white font-F1Bold uppercase flex items-center">
                            {race.data?.name}
                        </div>
                    </div>
                </Dialog.Title>
            </div>
            <div className="mt-8 relative flex-1 items-center px-4 sm:px-6">
                <h1 className="font-F1Bold text-xl text-white mb-4 pb-2 border-b-2 border-slate-700">
                    Qualifications
                </h1>
                <table className="w-full">
                    <thead>
                        <tr className="font-F1 text-xs text-gray-400 text-left">
                            <th />
                            <th>Pilote</th>
                            <th>Écurie</th>
                            <th>Temps</th>
                        </tr>
                    </thead>
                    <tbody className="text-white text-sm">
                        {race.data?.results.qualifying
                            .sort((a, b) => a.position - b.position)
                            .map(({ driver, team, time }, key) => {
                                return (
                                    <tr key={driver._id}>
                                        <td className="py-1 font-F1Bold text-xs">
                                            {key + 1}
                                        </td>
                                        <td className="py-1">{driver.name}</td>
                                        <td className="py-1">
                                            <span
                                                className="border-l-4 mr-2"
                                                style={{
                                                    borderColor: team.color,
                                                }}
                                            />
                                            {team.name}
                                        </td>
                                        <td>
                                            {Duration.fromMillis(time).toFormat(
                                                "m:ss.SSS"
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <h1 className="font-F1Bold text-xl text-white mt-8 mb-4 pb-2 border-b-2 border-slate-700">
                    Course
                </h1>
                <h2 className="font-F1Bold text-sm text-center text-slate-300 mb-4">
                    Classement Pilotes
                </h2>
                <table className="w-full">
                    <thead>
                        <tr className="font-F1 text-xs text-gray-400 text-left">
                            <th />
                            <th>Pilote</th>
                            <th>Écurie</th>
                            <th>Pts</th>
                        </tr>
                    </thead>
                    <tbody className="text-white text-sm">
                        {getDriverStanding(
                            race.data?.results.race,
                            race.data?.results.qualifying
                        )
                            .sort((a, b) => a.position - b.position)
                            .map(({ driver, team, points }, key) => {
                                return (
                                    <tr key={driver._id}>
                                        <td className="py-1 font-F1Bold text-xs">
                                            {key + 1}
                                        </td>
                                        <td className="py-1">{driver.name}</td>
                                        <td className="py-1">
                                            <span
                                                className="border-l-4 mr-2"
                                                style={{
                                                    borderColor: team.color,
                                                }}
                                            />
                                            {team.name}
                                        </td>
                                        <td>{points}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <span className="border-t-2 border-t-slate-700 w-full block my-4" />
                <h2 className="font-F1Bold text-sm text-center text-slate-300 mb-4">
                    Classement Écuries
                </h2>
                <table className="w-full">
                    <thead>
                        <tr className="font-F1 text-xs text-gray-400 text-left">
                            <th />
                            <th>Écurie</th>
                            <th>Pts</th>
                        </tr>
                    </thead>
                    <tbody className="text-white text-sm">
                        {getTeamsStanding(
                            race.data?.results.race,
                            race.data?.results.qualifying
                        ).map(({ team, points }, key) => {
                            return (
                                <tr key={team._id}>
                                    <td className="py-1 font-F1Bold text-xs">
                                        {key + 1}
                                    </td>
                                    {/*<td className="py-1">{team.name}</td>*/}
                                    <td className="py-1">
                                        <span
                                            className="border-l-4 mr-2"
                                            style={{
                                                borderColor: team.color,
                                            }}
                                        />
                                        {team.name}
                                    </td>
                                    <td>{points}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="text-center mt-8">
                    <NavLink
                        to={`/races/${raceId}`}
                        className=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-500"
                        onClick={() => close()}
                    >
                        Plus de détails
                        <InformationCircleIcon
                            className="ml-2 -mr-1 h-5 w-5"
                            aria-hidden="true"
                        />
                    </NavLink>
                    {/*<button type="button"></button>*/}
                </div>
            </div>
        </>
    );
}

const getTeamsStanding = (race, qualifying) => {
    return race && qualifying
        ? Object.entries(groupBy(race, "team._id"))
              .map(([key, team]) => {
                  return {
                      points: team.reduce(
                          (res, driver) =>
                              (res +=
                                  driver.points +
                                  qualifying.find(
                                      (d) => d.driver._id === driver.driver._id
                                  ).points),
                          0
                      ),
                      team: team[0].team,
                      drivers: team,
                  };
              })
              .sort((a, b) => b.points - a.points)
        : [];
};

const getDriverStanding = (race, qualifying) => {
    return race && qualifying
        ? race.map((driver) => ({
              ...driver,
              points:
                  driver.points +
                  qualifying.find((d) => d.driver._id === driver.driver._id)
                      .points,
          }))
        : [];
};

export default RaceStandingAside;
