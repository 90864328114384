import React from "react";
import { useRaceQuery } from "queries/races";
import { useParams } from "react-router-dom";
import RaceHeader from "pages/Public/Races/components/RaceHeader";
import RaceCircuit from "pages/Public/Races/components/RaceCircuit";
import RaceDrivers from "pages/Public/Races/components/RaceDrivers";
import RaceRegister from "pages/Public/Races/components/RaceRegister";
import RaceQualifyingResult from "pages/Public/Races/components/RaceQualifyingResult";
import RaceResult from "pages/Public/Races/components/RaceResult";

function Race() {
    const { raceId } = useParams();
    const { data: race, isSuccess } = useRaceQuery(raceId, {
        populate: "team, driver, circuit",
    });

    if (isSuccess)
        return (
            <div className="w-full max-w-7xl mx-auto xl:px-8">
                <RaceHeader {...race} />
                {(race.results.race.length > 0 ||
                    race.results.qualifying.length > 0) && (
                    <div className="mt-8">
                        <h2 className="font-F1Bold text-xl text-slate-300 mb-4">
                            Résultats
                        </h2>
                        <div className="grid lg:grid-cols-2 gap-x-4">
                            {race.results.race.length > 0 && (
                                <RaceResult drivers={race.results.race} />
                            )}
                            {race.results.qualifying.length > 0 && (
                                <RaceQualifyingResult
                                    drivers={race.results.qualifying}
                                />
                            )}
                        </div>
                    </div>
                )}
                <RaceDrivers {...race.drivers} />
                {race?.registrationOpened && <RaceRegister {...race} />}
                <RaceCircuit {...race} />
            </div>
        );
    else return "";
}

export default Race;
