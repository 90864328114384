import Dashboard from "pages/Admin/Dashboard";
import Races from "pages/Admin/Races";
import Drivers from "pages/Admin/Drivers";
import Driver from "pages/Admin/Driver";
import Teams from "pages/Admin/Teams";
import Team from "pages/Admin/Team";
import Race from "pages/Admin/Race";
import RaceInformations from "pages/Admin/Race/RaceInformations";
import RaceQualifying from "pages/Admin/Race/RaceQualifying";
import RaceRegistrations from "pages/Admin/Race/RaceRegistrations";
import RaceResults from "pages/Admin/Race/RaceResults";

export const adminRoutes = [
    {
        index: true,
        element: <Dashboard />,
    },
    {
        path: "races",
        element: <Races />,
    },
    {
        path: "races/:raceId",
        element: <Race />,
        routes: [
            {
                index: true,
                element: <RaceInformations />,
            },
            {
                path: "registrations",
                element: <RaceRegistrations />,
            },
            {
                path: "qualifying",
                element: <RaceQualifying />,
            },
            {
                path: "race",
                element: <RaceResults />,
            },
        ],
    },
    {
        path: "drivers",
        element: <Drivers />,
    },
    {
        path: "drivers/:driverId",
        element: <Driver />,
    },
    {
        path: "teams",
        element: <Teams />,
    },
    {
        path: "teams/:teamId",
        element: <Team />,
    },
];
