import React from "react";
import TeamCard from "./components/TeamCard";
import { useTeamsStandingQuery } from "../../../queries/standings";
import {useSelector} from "react-redux";

function Teams() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const teamsStanding = useTeamsStandingQuery(currentSeasonId);

    return (
        <div className="w-full max-w-7xl mx-auto xl:px-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-8">
            {teamsStanding.data?.map(({ team, points, drivers }, key) => {
                return (
                    <TeamCard
                        key={team._id}
                        position={key + 1}
                        team={team}
                        drivers={drivers}
                        points={points}
                    />
                );
            })}
        </div>
    );
}

export default Teams;
