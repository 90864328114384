import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomField from "components/CustomField";
import { CogIcon, SaveIcon } from "@heroicons/react/outline";
import {
    useAvailableRaceNumbersQuery,
    useRegisterDriverMutation,
} from "queries/drivers";
import {useSelector} from "react-redux";

function RegisterInformations() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const { data: availableRaceNumbers } = useAvailableRaceNumbersQuery(
        currentSeasonId
    );
    const {
        mutate: registerDriver,
        isLoading,
        error,
        isError,
    } = useRegisterDriverMutation();

    return (
        <div className="flex flex-col items-center gap-y-4">
            <p className="text-white">
                Pour finaliser ton inscription, tu dois choisir un numéro de
                voiture et renseigner ton code de contact Steam.
                <br />
                Le numéro de voiture indiqué devra être utilisé lors des courses
                de la Ligue Azgharienne.
            </p>
            <Formik
                initialValues={{
                    raceNumber: "",
                    steamContactCode: "",
                }}
                validationSchema={Yup.object().shape({
                    raceNumber: Yup.number().required("Required"),
                    steamContactCode: Yup.string()
                        .min(2, "Too Short!")
                        .required("Required"),
                })}
                validateOnMount
                onSubmit={async (values) => registerDriver(values)}
            >
                {({ isValid }) => (
                    <Form className="w-full">
                        <div className="grid grid-flow-row lg:grid-cols-2 gap-4 mb-4">
                            <Field
                                component={CustomField}
                                type="select"
                                name="raceNumber"
                                label="Numéro de voiture *"
                                disabled={isLoading}
                            >
                                <option value="">Sélectionne un numéro</option>
                                {availableRaceNumbers?.map((raceNumber) => (
                                    <option key={raceNumber} value={raceNumber}>
                                        {raceNumber}
                                    </option>
                                ))}
                            </Field>
                            <Field
                                component={CustomField}
                                name="steamContactCode"
                                label="Code de contact Steam *"
                                disabled={isLoading}
                            />
                        </div>
                        <p className="text-white text-sm">* Champ requis</p>
                        {isError && (
                            <p className="text-white text-center mt-4">
                                {error?.response?.data?.type ===
                                    "RACE_NUMBER_NOT_AVAILABLE" &&
                                    "La numéro de voiture est déjà utilisé"}
                                {error?.response?.data?.type !==
                                    "RACE_NUMBER_NOT_AVAILABLE" &&
                                    "Problème lors de la confirmation de l'inscription"}
                            </p>
                        )}
                        <div className="flex justify-center mt-6">
                            <button
                                className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                                type="submit"
                                disabled={!isValid}
                            >
                                <span>Confirmer</span>
                                {!isLoading && <SaveIcon className="h-5 w-5" />}
                                {isLoading && (
                                    <CogIcon className="h-5 w-5 animate-spin" />
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default RegisterInformations;
