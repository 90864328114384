import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

export const useTeamsQuery = (season) => {
    return useQuery(
        ["teams", season],
        async () =>
            (
                await axios.get(`/teams`, {
                    params: {
                        query: {
                            season,
                        },
                        sort: "name",
                        pageSize: 11
                    },
                })
            ).data
    );
};

export const useTeamQuery = (teamId, params, options) => {
    return useQuery(
        [...["team", teamId], ...(params ? [params] : [])],
        async () =>
            (
                await axios.get(`/teams/${teamId}`, {
                    params: params,
                })
            ).data,
        options
    );
};

export const useUpdateTeamMutation = (options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return axios.put(`/teams/${values._id}`, values);
        },
        options || {
            onSuccess: async (result, values) => {
                await queryClient.invalidateQueries(["team", values._id]);
                await queryClient.invalidateQueries(["teams"]);
            },
        }
    );
};
