import React from "react";
import { useTeamsStandingQuery } from "queries/standings";
import {useSelector} from "react-redux";

function TeamsStanding() {
    const currentSeasonId = useSelector((state) => state.public.season);
    const teamsStanding = useTeamsStandingQuery(currentSeasonId);

    return (
        <table className="w-full mt-6">
            <thead>
                <tr className="font-F1 text-xs text-gray-400 text-left">
                    <th>Pos</th>
                    <th />
                    <th>Écurie</th>
                    <th>Pts</th>
                </tr>
            </thead>
            <tbody className="text-white text-sm">
                {teamsStanding.data?.map(({ team, points }, key) => {
                    return (
                        <tr key={team._id}>
                            <td className="py-1">{key + 1}</td>
                            <td className="py-1">
                                <div
                                    className="flex items-center justify-center h-7 w-7 rounded-full p-1"
                                    style={{ backgroundColor: team.color }}
                                >
                                    {team.icon && (
                                        <img
                                            src={`teams/icon/${team.icon}`}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </td>
                            <td className="py-1">{team.name}</td>
                            <td className="py-1">{points}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default TeamsStanding;
