/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useRaceQuery, useUpdateRaceMutation } from "queries/races";

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { PlusIcon, ViewGridIcon } from "@heroicons/react/solid";

import { Formik, Field, Form, FieldArray } from "formik";
import CustomField from "components/CustomField";
import { Duration } from "luxon";
import { CogIcon, SaveIcon } from "@heroicons/react/outline";

function RaceResults() {
    const { raceId } = useParams();
    useRaceQuery(
        raceId,
        { populate: "driver, team" },
        {
            onSuccess: (data) => {
                setStanding(
                    data.results.race
                        .sort((a, b) => a.position - b.position)
                        .map((d) => ({
                            ...d,
                            totalRaceTime: Duration.fromMillis(
                                d.totalRaceTime
                            ).toFormat("hh:mm:ss.SSS"),
                            bestLapTime: Duration.fromMillis(
                                d.bestLapTime
                            ).toFormat("m:ss.SSS"),
                        }))
                );
                setDrivers(data.drivers.active);
            },
            refetchOnWindowFocus: false,
        }
    );
    const { mutate: updateRace, isLoading } = useUpdateRaceMutation();

    const [drivers, setDrivers] = useState([]);
    const [standing, setStanding] = useState([]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setStanding((items) => {
                const oldIndex = items.findIndex(
                    (i) => i.driver._id === active.id
                );
                const newIndex = items.findIndex(
                    (i) => i.driver._id === over.id
                );

                return arrayMove(items, oldIndex, newIndex).map(
                    (item, index) => {
                        let points = 0;

                        switch (index) {
                            case 0:
                                points = 25;
                                break;
                            case 1:
                                points = 18;
                                break;
                            case 2:
                                points = 15;
                                break;
                            case 3:
                                points = 12;
                                break;
                            case 4:
                                points = 10;
                                break;
                            case 5:
                                points = 8;
                                break;
                            case 6:
                                points = 6;
                                break;
                            case 7:
                                points = 4;
                                break;
                            case 8:
                                points = 2;
                                break;
                            case 9:
                                points = 1;
                                break;
                            default:
                                points = 0;
                        }
                        return {
                            ...item,
                            position: index + 1,
                            points: points,
                        };
                    }
                );
            });
        }
    };

    return (
        <>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-2">
                    <h2 className="font-F1 text-sm text-white mb-2">Pilotes</h2>
                    <div className="space-y-2">
                        {drivers
                            .filter(
                                (d) =>
                                    !standing.find(
                                        (s) => s.driver._id === d.driver._id
                                    )
                            )
                            .map((driver) => {
                                return (
                                    <div
                                        key={driver.driver._id}
                                        className="flex py-2 items-center"
                                    >
                                        {driver.team && (
                                            <div
                                                className="mr-2 cursor-pointer bg-slate-600 rounded p-1"
                                                onClick={() => {
                                                    setStanding((prevState) => {
                                                        let points = 0;

                                                        switch (
                                                            prevState.length
                                                                ? prevState[
                                                                      prevState.length -
                                                                          1
                                                                  ].position
                                                                : 0
                                                        ) {
                                                            case 0:
                                                                points = 25;
                                                                break;
                                                            case 1:
                                                                points = 18;
                                                                break;
                                                            case 2:
                                                                points = 15;
                                                                break;
                                                            case 3:
                                                                points = 12;
                                                                break;
                                                            case 4:
                                                                points = 10;
                                                                break;
                                                            case 5:
                                                                points = 8;
                                                                break;
                                                            case 6:
                                                                points = 6;
                                                                break;
                                                            case 7:
                                                                points = 4;
                                                                break;
                                                            case 8:
                                                                points = 2;
                                                                break;
                                                            case 9:
                                                                points = 1;
                                                                break;
                                                            default:
                                                                points = 0;
                                                        }

                                                        return [
                                                            ...prevState,
                                                            {
                                                                ...driver,
                                                                bestLapTime: "",
                                                                totalRaceTime:
                                                                    "",
                                                                points: points,
                                                                resultStatus: 3,
                                                                position:
                                                                    prevState.length
                                                                        ? prevState[
                                                                              prevState.length -
                                                                                  1
                                                                          ]
                                                                              .position +
                                                                          1
                                                                        : 1,
                                                            },
                                                        ];
                                                    });
                                                }}
                                            >
                                                <PlusIcon className="h-4 w-4 text-white" />
                                            </div>
                                        )}
                                        <div className="text-white">
                                            {driver.driver.name}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="col-span-10">
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        modifiers={[restrictToVerticalAxis]}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={standing.map((item) => item.driver._id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Formik
                                initialValues={{ drivers: standing }}
                                onSubmit={async (values) => {}}
                                validate={(values) => {
                                    setStanding(values.drivers);
                                }}
                                enableReinitialize
                            >
                                {({ values }) => {
                                    return (
                                        <Form className="space-y-2">
                                            <div className="flex items-center grid grid-cols-12 gap-2 font-F1 text-sm text-white">
                                                <div />
                                                <div className="">Pos</div>
                                                <div className="col-span-2">
                                                    Pilote
                                                </div>
                                                <div className="col-span-2">
                                                    Écurie
                                                </div>
                                                <div className="col-span-2">
                                                    Meilleur temps
                                                </div>
                                                <div className="col-span-2">
                                                    Temps total
                                                </div>
                                                <div className="col-span-1">
                                                    Points
                                                </div>
                                                <div className="col-span-1">
                                                    Statut
                                                </div>
                                            </div>
                                            <FieldArray
                                                name="drivers"
                                                render={(arrayHelpers) => {
                                                    return values.drivers.map(
                                                        (driver, index) => {
                                                            return (
                                                                <SortableItem
                                                                    key={
                                                                        driver
                                                                            .driver
                                                                            ._id
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    id={
                                                                        driver
                                                                            .driver
                                                                            ._id
                                                                    }
                                                                    driver={
                                                                        driver
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    );
                                                }}
                                            />
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </SortableContext>
                    </DndContext>
                </div>
            </div>
            <div className="flex justify-end mt-12">
                <button
                    className="flex items-center rounded px-4 py-2 shadow-sm text-white bg-slate-600 hover:bg-slate-500 disabled:opacity-70 gap-x-2"
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                        updateRace({
                            _id: raceId,
                            "results.race": standing.map((s) => {
                                return {
                                    ...s,
                                    totalRaceTime: Duration.fromISOTime(
                                        `${s.totalRaceTime}`
                                    ).toMillis(),
                                    bestLapTime: Duration.fromISOTime(
                                        `00:0${s.bestLapTime}`
                                    ).toMillis(),
                                };
                            }),
                        });
                    }}
                >
                    <span>Sauvegarder</span>
                    {!isLoading && <SaveIcon className="h-5 w-5" />}
                    {isLoading && <CogIcon className="h-5 w-5 animate-spin" />}
                </button>
            </div>
        </>
    );
}

const SortableItem = ({ index, id, driver }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: id, data: { driver: driver } });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="flex items-center grid grid-cols-12 gap-2"
        >
            <ViewGridIcon
                className="h-5 w-5 text-slate-300"
                {...attributes}
                {...listeners}
            />
            <div className="font-F1Bold text-white">{driver.position}</div>
            <div className="col-span-2 text-md text-white">
                {driver.driver.name}
            </div>
            <div
                className="col-span-2 text-md text-white border-l-4 pl-2"
                style={{
                    borderLeftColor: driver.team.color,
                }}
            >
                {driver.team.name}
            </div>
            {index !== undefined && (
                <>
                    <Field
                        name={`drivers.${index}.bestLapTime`}
                        component={CustomField}
                        className="col-span-2"
                        type="duration"
                    />
                    <Field
                        name={`drivers.${index}.totalRaceTime`}
                        component={CustomField}
                        className="col-span-2"
                        type="duration"
                        mask="99:99:99.999"
                    />
                    <Field
                        name={`drivers.${index}.points`}
                        component={CustomField}
                        className="col-span-1"
                    />

                    <Field
                        name={`drivers.${index}.resultStatus`}
                        component={CustomField}
                        className="col-span-1"
                        type="select"
                    >
                        {/*<option value={0}>Invalide</option>*/}
                        {/*<option value={1}>Inactif</option>*/}
                        {/*<option value={2}>Actif</option>*/}
                        <option value={3}>Terminé</option>
                        <option value={4}>Non terminé (DNF)</option>
                        <option value={5}>Disqualifié (DSQ)</option>
                        <option value={6}>Non classé</option>
                        <option value={7}>Retiré</option>
                    </Field>
                </>
            )}
        </div>
    );
};

export default RaceResults;
