import React from "react";

function DriverCard({ position, driver, points }) {
    return (
        <div
            className="flex flex-col bg-slate-700 border-l-2 border-l-slate-500 rounded p-4"
            style={{
                borderLeftColor: driver.team?.color,
            }}
        >
            <div className="flex flex-row justify-between items-center border-b border-slate-500 py-2">
                <div className="font-F1Black text-white text-5xl">
                    {position}
                </div>
                <div className="flex flex-col text-white">
                    <span className="font-F1Wide text-right text-xl">
                        {points}
                    </span>
                    <span className="font-F1Wide text-right text-sm text-slate-300">
                        PTS
                    </span>
                </div>
            </div>
            <div className="flex flex-col justify-between border-b border-slate-500 py-2">
                <div className="font-F1Bold text-xl text-white">
                    {driver.name}
                </div>
                <div className="font-F1 text-sm text-white text-slate-300">
                    {driver.team?.name || <>&nbsp;</>}
                </div>
                {/*<img className="w-10 h-10" src={driver.discord.avatar} alt="" />*/}
            </div>
            <div className="flex flex-col items-center justify-center pt-4">
                <img
                    className="rounded-full border-4 border-slate-500 w-32 h-32"
                    src={
                        driver.user?.discord?.avatar
                            ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                            : "/avatar.png"
                    }
                    alt=""
                />
                <span className="font-F1Black text-2xl text-white bg-slate-400 border-slate-500 border-4 rounded-xl -mt-8 drop-shadow-md p-2">
                    {driver.raceNumber < 10
                        ? `0${driver.raceNumber}`
                        : driver.raceNumber}
                </span>
            </div>
        </div>
    );
}

export default DriverCard;
