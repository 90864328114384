import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useDriverMeQuery = (options) => {
    return useQuery(
        ["driverMe"],
        async () => (await axios.get(`/drivers/me`)).data,
        options
    );
};

export const useDriverQuery = (driverId, params, options) => {
    return useQuery(
        [...["driver", driverId], ...(params ? [params] : [])],
        async () =>
            (
                await axios.get(`/drivers/${driverId}`, {
                    params: params,
                })
            ).data,
        options
    );
};

export const useDriversQuery = (season, query, params) => {
    return useQuery(
        ["drivers", season, query, params],
        async () =>
            (
                await axios.get(`/drivers`, {
                    params: {
                        query: {
                            season,
                            ...query,
                        },
                        populate: "user, team",
                        pageSize: 50,
                        ...params,
                    },
                })
            ).data
    );
};

export const useAvailableRaceNumbersQuery = (season) => {
    return useQuery(["availableRaceNumbers", season], async () => {
        const usedRaceNumbers = (
            await axios.get(`/drivers`, {
                params: {
                    query: {
                        season,
                    },
                    pageSize: 100,
                    fields: "raceNumber",
                },
            })
        ).data?.rows.map((driver) => driver.raceNumber);

        return raceNumbers.filter(
            (raceNumber) => !usedRaceNumbers.includes(raceNumber)
        );
    });
};

export const useUpdateRaceMutation = (options) => {
    const queryClient = useQueryClient();

    return useMutation(
        (values) => {
            return axios.put(`/drivers/${values._id}`, {
                ...values,
                team: values?.team?._id || null,
            });
        },
        options || {
            onSuccess: async (result, values) => {
                await queryClient.invalidateQueries(["driver", values._id]);
                await queryClient.invalidateQueries(["drivers"]);
            },
        }
    );
};

export const useRegisterDriverMutation = (options) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(
        (values) => {
            return axios.post(`/drivers/register`, values);
        },
        options || {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["availableRaceNumbers"]);
                navigate("../confirmation", { replace: true });
            },
            onError: async () => {
                await queryClient.invalidateQueries(["availableRaceNumbers"]);
            },
        }
    );
};

const raceNumbers = [
    2, 8, 12, 13, 15, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 32, 34, 35,
    36, 37, 38, 39, 40, 41, 42, 43, 45, 46, 48, 49, 50, 51, 52, 53, 54, 56, 57,
    58, 59, 60, 61, 62, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98,
];
