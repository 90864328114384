import { useQuery } from "react-query";
import axios from "axios";

export const useCircuitsQuery = (params, options) => {
    return useQuery(
        [...["circuits"], ...(params ? [params] : [])],
        async () =>
            (
                await axios.get(`/circuits`, {
                    params: params,
                })
            ).data,
        options
    );
};
