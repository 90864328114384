import React from "react";
import { Duration } from "luxon";

function RaceResult({ drivers }) {
    return (
        <div>
            <h3 className="font-F1Bold text-slate-300 mb-2">Course</h3>
            <div>
                <div className="grid grid-cols-8 p-1 odd:bg-slate-700 rounded font-bold text-white text-xs">
                    <div className="flex items-center pl-2">Pos.</div>
                    <div className="flex items-center col-span-3">Pilote</div>
                    <div className="flex items-center col-span-2">Écurie</div>
                    <div className="flex items-center justify-center">
                        Meilleur tps.
                    </div>
                    <div className="flex items-center justify-end pr-2">
                        Pts.
                    </div>
                </div>
                {drivers.map((d) => (
                    <Driver key={d.driver._id} {...d} />
                ))}
            </div>
        </div>
    );
}

function Driver({ driver, team, position, bestLapTime, resultStatus, points }) {
    return (
        <div className="grid grid-cols-8 p-1 odd:bg-slate-700 rounded">
            <div className="flex items-center font-F1Bold text-white pl-2">
                {position}
            </div>
            <div className="flex items-center text-white col-span-3">
                <img
                    className="rounded-full h-7 w-7 border-2 border-slate-500 mr-2"
                    src={
                        driver.user?.discord?.avatar
                            ? `https://cdn.discordapp.com/avatars/${driver.user?.discord?.id}/${driver.user?.discord?.avatar}.png`
                            : "/avatar.png"
                    }
                    alt=""
                />
                {driver.name}
            </div>
            <div className="flex items-center col-span-2">
                <span
                    className="border-l-4 pl-2 text-slate-300"
                    style={{
                        borderLeftColor: team?.color,
                    }}
                >
                    {team?.name}
                </span>
            </div>
            <div className="flex items-center justify-center text-white">
                {resultStatus === 4 && "DNF"}
                {resultStatus !== 4 &&
                    bestLapTime !== 0 &&
                    Duration.fromMillis(bestLapTime).toFormat("m:ss.SSS")}
            </div>
            <div className="flex items-center justify-end text-white pr-2">
                {points}
            </div>
        </div>
    );
}

export default RaceResult;
